import { useEffect, useLayoutEffect, useState } from "react";
import { View, Platform } from "react-native";
import { makeStyles } from "@rneui/themed";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import { useAcceptConsentsMutation } from "common/services/MemberConsentsService";
import { PushNotificationsHelper_isSupported } from "common/helpers/firebase/PushNotificationsHelper";
import { RootState, useAppDispatch } from "common/redux";
import { setAuthFlowFinished } from "common/redux/AuthSlice";

import ScreenContainer from "../../components/ui/ScreenContainer";
import { Biometrics_isSupported } from "../../helpers/BiometricsHelpers";

import Button from "../../components/ui/Button";
import Spacing from "../../components/ui/Spacing";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import CheckBox from "../../components/ui/CheckBox";
import Text from "../../components/ui/Text";
import StepIndicator from "../../components/ui/StepIndicator";
import { Linking_openURL } from "../../helpers/LinkingHelper";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const MissingLegalFormsScreen = ({ navigation, authenticatedFlow = false }) => {
  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);
  const dispatch = useAppDispatch();

  const { data: patient, isFetching } = useGetAuthenticatedMember(false, [
    MemberLinkedEntitiesEnum.MISSING_LEGAL_FORMS
  ]);

  const { notificationsEnabled } = useSelector(
    (state: RootState) => state.settings
  );

  const [acceptConsentsMutation, { isLoading: isPostLoading }] =
    useAcceptConsentsMutation();

  const [privacyChecked, setPrivacyChecked] = useState<boolean>(false);
  const [telehealthChecked, setTelehealthChecked] = useState<boolean>(false);
  const [benefitsChecked, setBenefitsChecked] = useState<boolean>(false);
  const [smsChecked, setSMSChecked] = useState<boolean>(false);

  const data = patient?.missing_legal_forms.filter(
    (item) => item.consent_type !== undefined
  );

  const goNext = () => {
    // This is required because we cannot call navigation object outside the navigation wrapper.
    // On Router.tsx we are using this and it is autoside navigation wrapper.
    // We need to check when consents are missing again so we prompt the user to accept them.
    if (authenticatedFlow) {
      return;
    }

    Biometrics_isSupported().then((supported) => {
      if (supported) {
        navigation.reset({
          index: 0,
          routes: [{ name: "Biometrics" }]
        });
      } else {
        PushNotificationsHelper_isSupported().then((isSupported) => {
          if (isSupported === false || (isSupported && notificationsEnabled)) {
            dispatch(setAuthFlowFinished());
          } else {
            navigation.reset({
              index: 0,
              routes: [{ name: "TurnOnNotifications" }]
            });
          }
        });
      }
    });
  };

  const handleSubmit = () => {
    const consentTypes = data.map((consent) => consent.consent_type);

    acceptConsentsMutation({
      member_id: patient.patient.patient_id,
      acceptedConsents: consentTypes
    });
  };

  useEffect(() => {
    // patient === undefined means that the patient is still loading.
    // After it, if missing consents is undefined, then there is no consent to accept, so it should move forward.
    if (patient === undefined) return;
    if (data === undefined || data?.length === 0) goNext();
  }, [data, patient]);

  useLayoutEffect(() => {
    if (patient === undefined) return;
    if (data === undefined || data?.length === 0) goNext();
  });

  return (
    <ScreenContainer
      innerStyle={styles.container}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <StepIndicator size={5} index={2} />

      <Spacing vertical={2} />

      <View style={styles.formContainer}>
        {isFetching || data === undefined || data?.length === 0 ? (
          <ActivityIndicator style={styles.activityIndicator} />
        ) : (
          <>
            <Text body style={styles.bodyText}>
              {LocalizedStrings.screens.missingLegalForms.header}
            </Text>

            <View style={styles.row} aria-label="TermsCheckbox">
              <CheckBox
                testID="TermsCheckbox"
                checked={privacyChecked}
                onPress={() => setPrivacyChecked(!privacyChecked)}
              />

              <Text body style={styles.bodyText}>
                {LocalizedStrings.screens.missingLegalForms.iAgreeToThe}{" "}
                <Text
                  link
                  onPress={() =>
                    Linking_openURL(
                      "https://copilotiq.com/terms-of-use/",
                      dispatch
                    )
                  }
                >
                  {LocalizedStrings.screens.missingLegalForms.termsOfService}
                </Text>
                {", "}
                <Text
                  link
                  onPress={() =>
                    Linking_openURL(
                      "https://copilotiq.com/privacy-policy/",
                      dispatch
                    )
                  }
                >
                  {LocalizedStrings.screens.missingLegalForms.privacyPolicy}
                </Text>
                {", "}
                <Text
                  link
                  onPress={() =>
                    Linking_openURL(
                      "https://copilotiq.com/notice-of-privacy-practices/",
                      dispatch
                    )
                  }
                >
                  {
                    LocalizedStrings.screens.missingLegalForms
                      .noticeOfPrivacyPractices
                  }
                </Text>
              </Text>
            </View>

            <View style={styles.row} aria-label="InformedCheckbox">
              <CheckBox
                testID="InformedCheckbox"
                checked={telehealthChecked}
                onPress={() => setTelehealthChecked(!telehealthChecked)}
              />

              <Text body style={styles.bodyText}>
                {LocalizedStrings.screens.missingLegalForms.iAgreeTo}{" "}
                <Text
                  link
                  onPress={() =>
                    Linking_openURL(
                      "https://copilotiq.com/telehealth-informed-consent/",
                      dispatch
                    )
                  }
                >
                  {
                    LocalizedStrings.screens.missingLegalForms
                      .telehealthInformedConsent
                  }
                </Text>{" "}
                {LocalizedStrings.common.and}{" "}
                <Text
                  link
                  onPress={() =>
                    Linking_openURL(
                      "https://copilotiq.com/rpm-informed-consent/",
                      dispatch
                    )
                  }
                >
                  {
                    LocalizedStrings.screens.missingLegalForms
                      .remotePatientMonitoringInformedConsent
                  }
                </Text>
              </Text>
            </View>

            <View style={styles.row} aria-label="AssignmentCheckbox">
              <CheckBox
                testID="AssignmentCheckbox"
                checked={benefitsChecked}
                onPress={() => setBenefitsChecked(!benefitsChecked)}
              />

              <Text body style={styles.bodyText}>
                {LocalizedStrings.screens.missingLegalForms.iAgreeToThe}{" "}
                <Text
                  link
                  onPress={() =>
                    Linking_openURL(
                      "https://copilotiq.com/assignment-of-benefits/",
                      dispatch
                    )
                  }
                >
                  {
                    LocalizedStrings.screens.missingLegalForms
                      .assignmentOfBenefits
                  }
                </Text>
              </Text>
            </View>

            {Platform.OS === "web" && (
              <View style={styles.row} aria-label="SmsCheckbox">
                <CheckBox
                  testID="SmsCheckbox"
                  checked={smsChecked}
                  onPress={() => setSMSChecked(!smsChecked)}
                />

                <Text body style={styles.bodyText}>
                  {LocalizedStrings.screens.missingLegalForms.iAgreeToReceive}{" "}
                  <Text
                    link
                    onPress={() =>
                      Linking_openURL(
                        "https://copilotiq.com/sms-consent/",
                        dispatch
                      )
                    }
                  >
                    {
                      LocalizedStrings.screens.missingLegalForms
                        .textMessagesFromCopilot
                    }
                  </Text>
                </Text>
              </View>
            )}
          </>
        )}
      </View>

      <Spacing vertical={2} />

      <Button
        disabled={
          privacyChecked === false ||
          benefitsChecked === false ||
          telehealthChecked === false ||
          (smsChecked === false && Platform.OS === "web") ||
          isFetching ||
          data?.length === 0
        }
        title={LocalizedStrings.screens.missingLegalForms.agreeAndContinue}
        accessibilityLabel={"Agree and Continue"}
        loading={isPostLoading}
        containerStyle={styles.buttonContainer}
        onPress={handleSubmit}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  const bottomInsets = insets.bottom + (Platform.OS === "android" ? 10 : 0);
  const marginBottom = Math.max(bottomInsets, 10);
  return {
    activityIndicator: {
      flex: 1
    },
    checkBoxContainer: {
      flexDirection: "row",
      alignItems: "center"
    },
    container: {
      flex: 1,
      marginHorizontal: 10
    },
    row: {
      flexDirection: "row",
      alignItems: "center"
    },
    bodyText: {
      color: theme.colors.darkGreyBlue,
      marginRight: 40
    },
    contentText: {
      color: theme.colors.greyBlue
    },
    underlineText: {
      textDecorationLine: "underline"
    },
    formContainer: {
      flex: 1,
      gap: 20
    },
    buttonContainer: {
      marginBottom
    }
  };
});

export default MissingLegalFormsScreen;
