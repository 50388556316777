import { useEffect, useState } from "react";
import { ScrollView, View } from "react-native";

import { makeStyles, useTheme } from "@rneui/themed";

import { Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";

import ScreenContainer from "../../../components/ui/ScreenContainer";
import Text from "../../../components/ui/Text";
import useTextStyles from "../../../components/ui/styles/useTextStyles";
import Spacing from "../../../components/ui/Spacing";
import IconButton from "../../../components/ui/IconButton";
import useViewStyles from "../../../components/ui/styles/useViewStyles";
import Button from "../../../components/ui/Button";
import Input from "../../../components/ui/Input";
import {
  DataType,
  FieldTypeEnum,
  HandleOptionsSelectedParams,
  OptionType,
  OptionTypeEnum,
  StringMap
} from "./QuestionsTypes";
import QuestionsData from "./QuestionsData";
import TouchableOpacity from "../../../components/ui/TouchableOpacity";
import LocalizedStrings from "../../../helpers/LocalizedStrings";

const RenderData = ({
  data,
  handleOptionSelected,
  handleSubmit
}: {
  data: DataType;
  handleOptionSelected: HandleOptionsSelectedParams;
  handleSubmit: () => void;
}) => {
  const { title, subTitle, options, submit } = data;
  const textStyles = useTextStyles();
  return (
    <View>
      {title !== undefined && (
        <Text h3 style={textStyles.colorDarkGreyBlue}>
          {title}
        </Text>
      )}
      <Spacing vertical={2} />
      {subTitle !== undefined && (
        <Text bodySmall style={textStyles.colorGreyBlue}>
          {subTitle}
        </Text>
      )}
      <Spacing vertical={4} />
      <RenderOptions
        data={data}
        options={options}
        handleOptionSelected={handleOptionSelected}
      />

      {submit && (
        <>
          <Spacing vertical={4} />
          <Button
            title={LocalizedStrings.common.submit}
            onPress={handleSubmit}
          />
          <Spacing vertical={4} />
        </>
      )}
    </View>
  );
};

const RenderOptions = ({
  data,
  options,
  handleOptionSelected
}: {
  data: DataType;
  options: OptionType[];
  handleOptionSelected: HandleOptionsSelectedParams;
}) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();

  const [selectedOptionId, setSelectedOptionId] = useState<number>();

  useEffect(() => {
    if (selectedOptionId === undefined) return;

    handleOptionSelected(selectedOptionId.toString(), data.field);
  }, [selectedOptionId]);

  const { type } = data;

  switch (type) {
    case OptionTypeEnum.SINGLE_SELECTION: {
      return (
        <View>
          {options
            .map<React.ReactNode>((option, index) => {
              const { text } = option;
              return (
                <TouchableOpacity
                  key={"OPTION_" + type + index.toString()}
                  style={[
                    viewStyles.cardContainer,
                    styles.center,
                    selectedOptionId === option.id &&
                      styles.singleSelectionOptionContainerSelected
                  ]}
                  onPress={() => setSelectedOptionId(option.id)}
                >
                  <Text capsSmall style={textStyles.colorTealAAA}>
                    {text}
                  </Text>
                </TouchableOpacity>
              );
            })
            .reduce((prev, curr) => [
              prev,
              <Spacing key={"SEPARATOR_" + prev.toString()} vertical={4} />,
              curr
            ])}
        </View>
      );
    }

    case OptionTypeEnum.THUMBS_BOOLEAN:
      return (
        <View style={styles.thumbsContainer}>
          {options
            .map<React.ReactNode>((option, index) => {
              const { text, icon, next_data, id } = option;
              const { field } = data;
              return (
                <TouchableOpacity
                  key={"OPTION_" + index.toString()}
                  style={styles.thumbsOptionContainer}
                  onPress={() =>
                    handleOptionSelected(id.toString(), field, next_data)
                  }
                >
                  {icon !== undefined && (
                    <IconButton
                      disabled
                      color={theme.colors.tealBlue}
                      buttonSize={64}
                      iconColor={theme.colors.white}
                      icon={icon}
                    />
                  )}
                  <Spacing vertical={2} />
                  <Text>{text}</Text>
                </TouchableOpacity>
              );
            })
            .reduce((prev, curr) => [
              prev,
              <Spacing key={"SEPARATOR_" + prev.toString()} horizontal={4} />,
              curr
            ])}
        </View>
      );
    case OptionTypeEnum.FREE_TEXT:
      return (
        <View>
          {options
            .map<React.ReactNode>((option, index) => {
              const { text } = option;
              return (
                <Input
                  key={index.toString()}
                  onChangeText={(text) =>
                    handleOptionSelected(text, data.field)
                  }
                  placeholder="Type here..."
                  label={text}
                  multiline
                />
              );
            })
            .reduce((prev, curr) => [
              prev,
              <Spacing key={"SEPARATOR_" + prev.toString()} horizontal={4} />,
              curr
            ])}
        </View>
      );
    default:
      return null;
  }
};

const RateAppointmentScreen = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const styles = useStyles();

  const [currentData, setCurrentData] = useState<DataType[]>([QuestionsData]);

  const [answers, setAnswers] = useState<StringMap>({});

  const handleOptionSelected = (
    answer: string,
    field: FieldTypeEnum,
    nextData?: DataType[]
  ) => {
    let newAnswers = { ...answers };
    newAnswers[field.toString()] = answer;
    setAnswers(newAnswers);

    if (nextData !== undefined) {
      setCurrentData(nextData);
    }
  };

  const handleSubmit = () => {
    navigation.popToTop();
    Alert_show({
      dispatch,
      title: LocalizedStrings.screens.rateAppointment.thankYouForTheFeedback,
      type: "success"
    });
  };

  return (
    <ScreenContainer>
      <ScrollView contentContainerStyle={styles.container}>
        <Spacing vertical={4} />
        {currentData.map((data, index) => {
          return (
            <RenderData
              key={index.toString()}
              data={data}
              handleOptionSelected={handleOptionSelected}
              handleSubmit={handleSubmit}
            />
          );
        })}
      </ScrollView>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginHorizontal: 20
    },
    thumbsContainer: {
      flexDirection: "row"
    },
    thumbsOptionContainer: {
      flex: 1,
      aspectRatio: 1,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: theme.colors.tealBlue,
      alignItems: "center",
      justifyContent: "center"
    },
    center: {
      justifyContent: "center",
      alignItems: "center"
    },
    singleSelectionOptionContainerSelected: {
      borderColor: theme.colors.tealBlue,
      backgroundColor: theme.colors.tealBlue + "1A"
    }
  };
});

export default RateAppointmentScreen;
