import { DataType, OptionTypeEnum, FieldTypeEnum } from "./QuestionsTypes";

const NEGATIVE_DATA = [
  {
    title: "Sorry to hear that, but we want to do better.",
    subTitle: `Tell us what you didn't like about the interaction.`,
    submit: false,
    type: OptionTypeEnum.SINGLE_SELECTION,
    field: FieldTypeEnum.DROPDOWN,
    options: [
      {
        id: 5,
        text: "LATE TO APPOINTMENT"
      },
      {
        id: 6,
        text: `FEEDBACK WAS NOT USEFUL`
      },
      {
        id: 7,
        text: "DID NOT LISTEN TO CONCERNS"
      },
      {
        id: 8,
        text: "FELT RUSHED"
      }
    ]
  },
  {
    type: OptionTypeEnum.FREE_TEXT,
    field: FieldTypeEnum.IMPROVE_FREETEXT,
    options: [{ id: 9, text: "HOW CAN WE IMPROVE?" }],
    submit: true
  }
];

const POSITIVE_DATA = {
  title: "Fantastic!",
  subTitle:
    "What did you like most about the interaction? You can select more than one option.",
  submit: true,
  type: OptionTypeEnum.SINGLE_SELECTION,
  field: FieldTypeEnum.DROPDOWN,
  options: [
    {
      id: 1,
      text: "PUNCTUAL"
    },
    { id: 2, text: "THOROUGH" },
    {
      id: 3,
      text: "GREAT LISTENER"
    },
    {
      id: 4,
      text: "ACTIONABLE FEEDBACK"
    }
  ]
};

const QuestionsData: DataType = {
  title: "How would you rate your interaction today?",
  subTitle:
    "We value your feedback, thank you for taking the time to share it with us.",
  type: OptionTypeEnum.THUMBS_BOOLEAN,
  submit: false,
  field: FieldTypeEnum.THUMBS,
  options: [
    {
      id: 9,
      icon: "thumbs-up",
      text: "It was great!",
      next_data: [POSITIVE_DATA]
    },
    {
      id: 10,
      icon: "thumbs-down",
      text: "Not so great",
      next_data: NEGATIVE_DATA
    }
  ]
};

export default QuestionsData;
