import { useCallback, useEffect, useRef } from "react";
import { makeStyles } from "@rneui/themed";
import { View, useWindowDimensions, ScaledSize, Image } from "react-native";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { useSafeAreaInsets } from "react-native-safe-area-context";

import {
  onboardingImage1,
  onboardingImage2,
  onboardingImage3
} from "../../assets/auth/onboarding";
import Button from "../../components/ui/Button";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import ViewPager, {
  ViewPagerRefInterface
} from "../../components/ui/ViewPager";
import { AuthStackParamList } from "../../navigation/AuthStackNavigator";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";
import ResponsiveBreakpoints from "../../constants/ResponsiveBreakpoints";
import AppInstallButtons from "../../components/AppInstallButtons";
import TouchableOpacity from "../../components/ui/TouchableOpacity";
import { Linking_openURL } from "../../helpers/LinkingHelper";
import { useAppDispatch } from "common/redux";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import Spacing from "../../components/ui/Spacing";

const INITIAL_PAGE = 0;
const DATA = [
  {
    key: "0",
    content: LocalizedStrings.screens.landing.onboarding1,
    image: onboardingImage1
  },
  {
    key: "1",
    content: LocalizedStrings.screens.landing.onboarding2,
    image: onboardingImage2
  },
  {
    key: "2",
    content: LocalizedStrings.screens.landing.onboarding3,
    image: onboardingImage3
  }
];

type ScreenProp = NavigationProp<AuthStackParamList, "Landing">;

const LandingScreen = () => {
  const insets = useSafeAreaInsets();
  const dimensions = useWindowDimensions();
  const styles = useStyles(dimensions);
  const navigation = useNavigation<ScreenProp>();
  const dispatch = useAppDispatch();

  const pagerRef = useRef<ViewPagerRefInterface>();

  const Page = useCallback(
    ({ content, image }) => {
      return (
        <View style={styles.pagerContainer}>
          <Image
            style={styles.pagerImage}
            source={image}
            resizeMode="contain"
          />
          <Text
            h2
            allowFontScaling={false}
            style={styles.pagerText}
            {...AccessibilityHelper_getAccessibilityProps("Landing Content")}
          >
            {content}
          </Text>
        </View>
      );
    },
    [styles]
  );

  useEffect(() => {
    const isSmallScreen =
      dimensions.height <
      ResponsiveBreakpoints.PHONE_SMALL_DEVICE_HEIGHT_BREAKPOINT;
    if (!isSmallScreen) return;
    navigation.setOptions({
      navigationBarHidden: true
    });

    return () =>
      navigation.setOptions({
        navigationBarHidden: false
      });
  }, [dimensions]);

  return (
    <ScreenContainer
      innerStyle={[styles.container]}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <View style={styles.viewPagerContainer}>
        <ViewPager
          ref={pagerRef}
          data={DATA}
          initialPage={INITIAL_PAGE}
          renderPage={(item) => (
            <Page key={item.key} content={item.content} image={item.image} />
          )}
        />
      </View>

      <View style={styles.containerButton}>
        <AppInstallButtons />

        <Button
          title={LocalizedStrings.common.next}
          onPress={() => {
            const isLastIndex = pagerRef?.current.isLastIndex();
            if (isLastIndex) {
              navigation.navigate("PhoneInput");
            } else {
              pagerRef?.current.nextPage();
            }
          }}
        />

        <TouchableOpacity
          style={[styles.learnMoreTouchable]}
          onPress={() => Linking_openURL("https://www.copilotiq.com", dispatch)}
        >
          <Text body style={styles.learnMoreText}>
            {LocalizedStrings.screens.landing.signUp1}{" "}
            <Text link>{LocalizedStrings.screens.landing.signUpLink}</Text>
          </Text>
        </TouchableOpacity>

        <Spacing vertical={2} />

        <Button
          containerStyle={{ marginBottom: Math.max(insets.bottom, 10) }}
          type="outline"
          title={LocalizedStrings.screens.landing.joiningAVideoCall}
          onPress={() => navigation.navigate("JoinVideoCall")}
        />
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme, { width, height }: ScaledSize) => {
  const imageSize = width / 2;

  return {
    container: { flex: 1 },
    pagerContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    viewPagerContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    pagerText: {
      maxWidth: 300,
      textAlign: "center",
      alignSelf: "center",
      color: theme.colors.darkGreyBlue
    },
    pagerImage: {
      width: imageSize,
      height: imageSize,
      maxWidth: 300,
      maxHeight: 300,
      alignSelf: "center"
    },

    learnMoreTouchable: {
      alignItems: "center"
    },
    learnMoreText: {
      color: theme.colors.greyBlue
    },
    containerButton: {
      margin: 10,
      gap: 10
    }
  };
});

export default LandingScreen;
