import { useEffect, useState } from "react";
import { Platform, View, ScrollView } from "react-native";
import { Divider, makeStyles, Switch } from "@rneui/themed";
import { useSelector } from "react-redux";
import Toast from "react-native-toast-message";
import useAppState from "react-native-appstate-hook";
import { useFormik } from "formik";

import {
  PermissionsHelper_askForPushNotifications,
  PermissionsHelper_hasPushNotification,
  PermissionsHelper_openSettings
} from "common/helpers/PermissionsHelper";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";

import { RootState, useAppDispatch } from "common/redux";
import {
  DISABLE_DARK_THEME,
  setBiometricsEnabled,
  setDarkThemeEnabled,
  setNotificationsEnabled,
  setUseOSThemeEnabled
} from "common/redux/SettingsSlice";
import { PushNotificationsHelper_isSupported } from "common/helpers/firebase/PushNotificationsHelper";
import {
  useGetPatientPreferencesQuery,
  useUpdateMemberPreferencesMutation
} from "common/services/MemberService";

import ActivityIndicator from "../../components/ui/ActivityIndicator";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import {
  Biometrics_sensorName,
  Biometrics_verify
} from "../../helpers/BiometricsHelpers";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import Spacing from "../../components/ui/Spacing";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import Dropdown from "../../components/ui/Dropdown";
import Button from "../../components/ui/Button";

const CADENCE_OPTIONS = [
  {
    label: "Every Week",
    value: "WEEKLY"
  },
  {
    label: "Every Other Week",
    value: "BIWEEKLY"
  }
];

const DAYS_OPTIONS = [
  {
    label: "Every Day",
    value: "EVERY_DAY",
    clearOthers: true
  },
  {
    label: "Monday",
    value: "MONDAY"
  },
  {
    label: "Tuesday",
    value: "TUESDAY"
  },
  {
    label: "Wednesday",
    value: "WEDNESDAY"
  },
  {
    label: "Thursday",
    value: "THURSDAY"
  },
  {
    label: "Friday",
    value: "FRIDAY"
  }
];

const TIMES_OPTIONS = [
  {
    label: "Every Time",
    value: "EVERY_TIME",
    clearOthers: true
  },
  {
    label: "Mornings",
    value: "MORNING"
  },
  {
    label: "Mid-Day",
    value: "MIDDAY"
  },
  {
    label: "Afternoons",
    value: "AFTERNOON"
  }
];

interface IListProps {
  name: string;
  description?: string;
  value: boolean;
  disabled?: boolean;
  onValueChange: (newValue: boolean) => void;
}

interface FormType {
  cadence: string[];
  days: string[];
  times: string[];
}

const ListItem = ({
  name,
  description,
  value,
  onValueChange,
  disabled = false
}: IListProps) => {
  const styles = useStyles();
  return (
    <View style={styles.row}>
      <View>
        <Text body style={styles.text}>
          {name}
        </Text>
        {description && (
          <Text italic style={styles.text}>
            {description}
          </Text>
        )}
      </View>
      <Switch value={value} onValueChange={onValueChange} disabled={disabled} />
    </View>
  );
};

const SettingsScreen = () => {
  const styles = useStyles();
  const { appState } = useAppState();
  const dispatch = useAppDispatch();

  const {
    biometricsEnabled,
    notificationsEnabled,
    darkThemeEnabled,
    useOSThemeEnabled
  } = useSelector((state: RootState) => state.settings);

  const { type } = useScreenType();

  const [biometricsSensorName, setBiometricsSensorName] = useState<string>();
  const [pushNotificationsSupported, setPushNotificationsSupported] =
    useState<boolean>();

  const { data: member, isLoading: isLoadingMember } =
    useGetAuthenticatedMember();

  const { data: memberPreferences, isLoading: isLoadingPreferences } =
    useGetPatientPreferencesQuery(
      {
        memberId: member?.patient?.patient_id
      },
      { skip: member === undefined }
    );

  const [
    updateMemberPreferences,
    { isLoading: memberPreferencesLoading, isSuccess }
  ] = useUpdateMemberPreferencesMutation();

  useEffect(() => {
    if (isSuccess) {
      Toast.show({
        type: "info",
        text1: LocalizedStrings.screens.settings.carePreferencesSaved,
        position: "bottom",
        bottomOffset: 120
      });
    }
  }, [isSuccess]);

  const isLoading = isLoadingPreferences || isLoadingMember;

  const formik = useFormik<FormType>({
    initialValues: {
      cadence: ["WEEKLY"],
      times: ["EVERY_TIME"],
      days: ["EVERY_DAY"]
    },
    onSubmit: (values) => {
      const { days, times, cadence } = values;

      let daysArray = days;
      if (days.includes("EVERY_DAY")) {
        daysArray = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];
      }

      let timesArray = times;
      if (times.includes("EVERY_TIME")) {
        timesArray = ["MORNING", "MIDDAY", "AFTERNOON"];
      }

      const times_of_day = {};
      for (let i = 0; i < daysArray.length; i++) {
        const day = daysArray[i];

        times_of_day[day] = timesArray;
      }

      const preferences = {
        appointment: {
          frequency: cadence[0],
          times_of_day
        }
      };

      updateMemberPreferences({
        memberId: member?.patient?.patient_id,
        data: preferences
      });
    },
    enableReinitialize: true
  });

  const { cadence, times, days } = formik.values;

  useEffect(() => {
    const appointment = memberPreferences?.preferences?.appointment;
    if (appointment === undefined) return;

    const timesOfDay = Object.keys(appointment?.times_of_day);
    const isEveryDay = timesOfDay.length === 5;

    const day = timesOfDay.length > 0 && timesOfDay[0];

    if (day !== undefined) {
      const isAnyTime =
        Object.keys(appointment?.times_of_day[day]).length === 3;
      formik.setFieldValue(
        "cadence",
        appointment?.frequency ? [appointment?.frequency] : []
      );

      if (isAnyTime) {
        formik.setFieldValue("times", ["EVERY_TIME"]);
      } else {
        formik.setFieldValue("times", appointment?.times_of_day[day] ?? []);
      }
    }

    if (isEveryDay) {
      formik.setFieldValue("days", ["EVERY_DAY"]);
    } else {
      formik.setFieldValue(
        "days",
        Object.keys(appointment?.times_of_day) ?? []
      );
    }
  }, [memberPreferences]);

  useEffect(() => {
    if (appState !== "active") return;

    PushNotificationsHelper_isSupported().then((supported) => {
      if (!supported) {
        setPushNotificationsSupported(false);
        return;
      }

      if (Platform.OS === "android") {
        // Doing this on android because popup creates an infinite loop (appState changes)
        PermissionsHelper_hasPushNotification()
          .then(({ enabled }) => {
            setPushNotificationsSupported(enabled);
          })
          .catch((error) => console.log(error));
      } else {
        PermissionsHelper_askForPushNotifications().then((enabled) => {
          setPushNotificationsSupported(enabled);
        });
      }
    });

    Biometrics_sensorName()
      .then((sensorName) => setBiometricsSensorName(sensorName))
      .catch((error) => console.log(error));
  }, [appState]);

  return (
    <ScreenContainer>
      <ScrollView>
        <Spacing vertical={2} />
        <View
          style={type !== ScreenTypeEnum.PHONE && styles.tabletListContainer}
        >
          {biometricsSensorName !== undefined && (
            <>
              <ListItem
                name={`${LocalizedStrings.common.enable} ${biometricsSensorName}`}
                value={biometricsEnabled}
                onValueChange={(value: boolean) => {
                  if (value === true) {
                    Biometrics_verify(
                      LocalizedStrings.screens.settings.verifyYourIdentity
                    )
                      .then((result) => {
                        dispatch(setBiometricsEnabled(result));
                      })
                      .catch((error) => dispatch(setBiometricsEnabled(false)));
                  } else {
                    dispatch(setBiometricsEnabled(value));
                  }
                }}
              />
              <Divider />
            </>
          )}

          <ListItem
            name={LocalizedStrings.screens.settings.notifications}
            description={
              LocalizedStrings.screens.settings.notificationsDescription
            }
            value={notificationsEnabled && pushNotificationsSupported}
            onValueChange={(value: boolean) => {
              if (pushNotificationsSupported === false) {
                PermissionsHelper_openSettings(dispatch);
              }
              dispatch(setNotificationsEnabled(value));
            }}
          />
          {!DISABLE_DARK_THEME && (
            <>
              <Divider />
              <ListItem
                name={LocalizedStrings.screens.settings.useOSTheme}
                value={useOSThemeEnabled}
                onValueChange={(value: boolean) => {
                  dispatch(setUseOSThemeEnabled(value));
                }}
              />
              <Divider />
              <ListItem
                name={LocalizedStrings.screens.settings.darkTheme}
                disabled={useOSThemeEnabled}
                value={darkThemeEnabled}
                onValueChange={(value: boolean) => {
                  dispatch(setDarkThemeEnabled(value));
                  setDarkThemeEnabled(!darkThemeEnabled);
                }}
              />
            </>
          )}
          {type === ScreenTypeEnum.PHONE && <Divider />}

          {isLoading ? (
            <ActivityIndicator style={styles.activityIndicator} />
          ) : (
            <View style={styles.row}>
              <View style={styles.flex1}>
                <Text body style={styles.text}>
                  {LocalizedStrings.screens.settings.carePreferences}
                </Text>
                <Text italic style={styles.text}>
                  {LocalizedStrings.screens.settings.carePreferencesDescription}
                </Text>

                <Dropdown
                  label={
                    LocalizedStrings.screens.settings.carePreferencesCadence
                  }
                  data={CADENCE_OPTIONS}
                  onValueChange={(value) => {
                    formik.setFieldValue("cadence", value);
                  }}
                  value={formik.values.cadence}
                />
                <Dropdown
                  label={LocalizedStrings.screens.settings.carePreferencesDays}
                  data={DAYS_OPTIONS}
                  onValueChange={(value) => {
                    formik.setFieldValue("days", value);
                  }}
                  multiSelect
                  value={formik.values.days}
                />
                <Dropdown
                  label={LocalizedStrings.screens.settings.carePreferencesTimes}
                  data={TIMES_OPTIONS}
                  onValueChange={(value) => {
                    formik.setFieldValue("times", value);
                  }}
                  multiSelect
                  value={formik.values.times}
                />
                <Spacing vertical={4} />
                <Button
                  title={LocalizedStrings.common.save}
                  onPress={formik.submitForm}
                  disabled={
                    cadence.length === 0 ||
                    days.length === 0 ||
                    times.length === 0
                  }
                  loading={memberPreferencesLoading}
                />
              </View>
            </View>
          )}
          {type === ScreenTypeEnum.PHONE && <Divider />}

          <Spacing vertical={40} />
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const MARGIN_SIDES = 20;

const useStyles = makeStyles((theme) => {
  return {
    row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginVertical: 10,
      paddingHorizontal: MARGIN_SIDES
    },
    flex1: { flex: 1 },
    tabletListContainer: {
      borderRadius: 20,
      borderWidth: 1,
      borderColor: theme.colors.grey4,
      margin: 20
    },
    text: {
      color: theme.colors.darkGreyBlue
    },
    activityIndicator: {
      height: 200
    }
  };
});

export default SettingsScreen;
