import { View } from "react-native";

import { makeStyles } from "@rneui/themed";

import Button from "../../components/ui/Button";
import ScreenContainer from "../../components/ui/ScreenContainer";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const ReauthenticationScreen = ({ navigation, route }) => {
  const textStyles = useTextStyles();
  const styles = useStyles();

  const { userName } = route.params;

  return (
    <ScreenContainer
      innerStyle={styles.container}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <View>
        <Text h1 style={textStyles.colorDarkGreyBlue}>
          {LocalizedStrings.screens.reauthentication.welcome}
          {userName}!
        </Text>
        <Spacing vertical={4} />
        <Text bodyLarge style={textStyles.colorGreyBlue}>
          {LocalizedStrings.screens.reauthentication.notActive}
        </Text>
      </View>

      <Button
        title={LocalizedStrings.screens.reauthentication.button}
        onPress={() => navigation.navigate("Landing")}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles(() => {
  return {
    container: {
      margin: 20,
      justifyContent: "space-evenly"
    }
  };
});

export default ReauthenticationScreen;
