import { useState } from "react";
import { View } from "react-native";
import { makeStyles } from "@rneui/themed";

import { EMAIL_VALIDATION_REGEXP } from "common/helpers/helpers";

import Input from "../../components/ui/Input";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";
import useKeyboard from "../../hooks/useKeyboard";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const FamilySharingScreen = ({ navigation }) => {
  const styles = useStyles();

  const isKeyboardVisible = useKeyboard();
  const [text, setText] = useState<string>("");

  return (
    <ScreenContainer innerStyle={styles.container}>
      <Text h4 style={styles.textDarkGreyBlue}>
        {LocalizedStrings.screens.familySharing.text1}
      </Text>
      <Text body style={styles.textGreyBlue}>
        {LocalizedStrings.screens.familySharing.text2}
      </Text>

      <Input
        label={LocalizedStrings.screens.familySharing.familyMemberPlaceholder}
        placeholder="example@email.com"
        onChangeText={(text) => setText(text)}
        textContentType="emailAddress"
        inputMode="email"
        autoFocus
      />

      <View style={styles.flex1} />

      {!isKeyboardVisible && (
        <Button
          title={LocalizedStrings.screens.familySharing.shareWithDoctor}
          onPress={() =>
            navigation.navigate("ConfirmFamilySharing", { email: text })
          }
          disabled={text.length === 0 || !EMAIL_VALIDATION_REGEXP.test(text)}
        />
      )}
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: 20,
      gap: 20
    },
    flex1: {
      flex: 1
    },
    textDarkGreyBlue: {
      color: theme.colors.darkGreyBlue
    },
    textGreyBlue: {
      color: theme.colors.greyBlue
    }
  };
});

export default FamilySharingScreen;
