import React from "react";
import { View } from "react-native";
import { makeStyles } from "@rneui/themed";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";
import { Alert_show } from "common/helpers/AlertHelper";
import useKeyboard from "../../hooks/useKeyboard";
import GoalForm from "../../components/home/GoalForm";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import { useAppDispatch } from "common/redux";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const AddGoalScreen = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const styles = useStyles();
  const textStyles = useTextStyles();
  const isKeyboardVisible = useKeyboard();

  return (
    <ScreenContainer>
      <View style={[styles.container, styles.margin]}>
        <Text h2 style={textStyles.colorDarkGreyBlue}>
          {LocalizedStrings.screens.goals.addNewGoal}
        </Text>

        <GoalForm onValueChange={() => {}} />
      </View>

      {!isKeyboardVisible && (
        <View style={styles.margin}>
          <Button
            title={LocalizedStrings.common.submit}
            onPress={() =>
              Alert_show({
                dispatch,
                title:
                  LocalizedStrings.screens.goals.createGoalSuccessTitle.replace(
                    "_TITLE_",
                    "test"
                  ),
                content:
                  LocalizedStrings.screens.goals.createGoalSuccessContent,
                buttons: [
                  {
                    text: LocalizedStrings.common.back,
                    onPress: () => navigation.goBack()
                  }
                ]
              })
            }
          />
        </View>
      )}
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      gap: 20
    },
    margin: {
      margin: 20
    }
  };
});
export default AddGoalScreen;
