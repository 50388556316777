import { useState, useMemo, useCallback, useEffect } from "react";
import { View, Platform, Keyboard, useWindowDimensions } from "react-native";
import { makeStyles } from "@rneui/themed";
import { DateTime } from "luxon";
import {
  NavigationProp,
  useFocusEffect,
  useNavigation
} from "@react-navigation/native";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import { AuthService_RequestSMSCode } from "common/services/AuthService";
import { Alert_show } from "common/helpers/AlertHelper";
import { RootState, useAppDispatch } from "common/redux";
import { logOut } from "common/redux/AuthSlice";

import Button from "../../components/ui/Button";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import PhoneInput from "../../components/ui/PhoneInput";
import DatePicker from "../../components/ui/DatePicker";
import StepIndicator from "../../components/ui/StepIndicator";
import { AuthStackParamList } from "../../navigation/AuthStackNavigator";
import { unmaskPhoneNumber } from "common/helpers/helpers";
import EnvVars from "common/config/EnvVars";

import Spacing from "../../components/ui/Spacing";
import AppVersion from "../../components/AppVersion";
import useKeyboard from "../../hooks/useKeyboard";
import ResponsiveBreakpoints from "../../constants/ResponsiveBreakpoints";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import LocalizedStringsCommon from "common/localizations/LocalizedStrings";

type ScreenProp = NavigationProp<AuthStackParamList, "PhoneInput">;

function getErrorMessage(statusCode: number) {
  switch (statusCode) {
    case 403:
      return LocalizedStringsCommon.error.sms_code;
    default:
      return LocalizedStringsCommon.error.default;
  }
}

const PhoneInputScreen = () => {
  const insets = useSafeAreaInsets();
  const { height } = useWindowDimensions();
  const keyboardVisible = useKeyboard();
  const styles = useStyles({ insets, height, keyboardVisible });

  const navigation = useNavigation<ScreenProp>();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [birthDate, setBirthdate] = useState<DateTime>();
  const [codeLoading, setCodeLoading] = useState<boolean>(false);
  const [preLoginId, setPreLoginId] = useState<string>();

  const { credentials } = useSelector((state: RootState) => state.auth);

  const dispatch = useAppDispatch();

  const requestCode = useCallback(() => {
    return new Promise<string>((resolve) => {
      setCodeLoading(true);
      AuthService_RequestSMSCode(birthDate, phoneNumber)
        .then((response) => {
          const { pre_login_id } = response.data;

          setPreLoginId(pre_login_id);
          setCodeLoading(false);
          resolve("OK");
        })
        .catch((err) => {
          const status = err?.response?.status;
          const errMessage = getErrorMessage(status);
          setCodeLoading(false);
          Alert_show({
            dispatch,
            title: LocalizedStringsCommon.error.title,
            content: errMessage,
            type: "error"
          });
        });
    });
  }, [birthDate, phoneNumber]);

  useEffect(() => {
    // This prevents the SMS confirmation code screen to auto log in when credentials != null
    // Only needed on screen startup
    if (credentials != null) dispatch(logOut(false));
  }, []);

  useEffect(() => {
    if (preLoginId) {
      navigation.navigate("SMSCode", {
        preLoginId,
        phoneNumber,
        birthDate: birthDate?.toISODate()
      });
    }
  }, [preLoginId, phoneNumber, birthDate]);

  useFocusEffect(
    useCallback(() => {
      return () => {
        setPreLoginId(undefined);
      };
    }, [])
  );

  const unmaskedPhoneNumber = useMemo(
    () => unmaskPhoneNumber(phoneNumber),
    [phoneNumber]
  );

  useEffect(() => {
    if (unmaskedPhoneNumber?.length >= 10) Keyboard.dismiss();
  }, [unmaskedPhoneNumber]);

  return (
    <ScreenContainer
      innerStyle={styles.container}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <StepIndicator size={5} index={0} />
      <View style={styles.formContainer}>
        <Text h1 style={styles.headerText} testID={"Hello"}>
          {LocalizedStrings.common.hello}!
        </Text>
        <Text bodyLarge style={styles.contentText}>
          {LocalizedStrings.screens.phoneInput.phoneAndDOB}
        </Text>
        <PhoneInput
          label={LocalizedStrings.common.phoneNumber.toUpperCase()}
          value={phoneNumber}
          onChangeText={(text) => setPhoneNumber(text)}
        />
        <DatePicker
          title={LocalizedStrings.common.dateOfBirth.toUpperCase()}
          date={birthDate}
          initialDate={DateTime.utc(1950)}
          onDateChanged={(date) => setBirthdate(date)}
        />
      </View>

      {EnvVars.REACT_APP_STACK_DEPLOYMENT_ENV !== "prod" &&
        !keyboardVisible && <AppVersion />}

      <Spacing vertical={2} />

      {!keyboardVisible && (
        <Button
          title={LocalizedStrings.common.submit}
          accessibilityLabel={"Submit"}
          testID={"Submit"}
          loading={codeLoading}
          containerStyle={styles.buttonContainer}
          disabled={
            unmaskedPhoneNumber.length !== 10 || birthDate === undefined
          }
          onPress={() => {
            requestCode();
          }}
        />
      )}
    </ScreenContainer>
  );
};

const useStyles = makeStyles(
  (
    theme,
    {
      insets,
      height,
      keyboardVisible
    }: { insets: EdgeInsets; height: number; keyboardVisible: boolean }
  ) => {
    const bottomInsets = insets.bottom + (Platform.OS === "android" ? 10 : 0);
    const marginBottom = Math.max(bottomInsets, 10);

    const isSmallScreen =
      height < ResponsiveBreakpoints.PHONE_SMALL_DEVICE_HEIGHT_BREAKPOINT;

    return {
      container: {
        flex: 1,
        marginHorizontal: 10
      },
      formContainer: {
        flex: 1,
        gap: 20,
        marginTop: 20,

        justifyContent:
          isSmallScreen || keyboardVisible ? "flex-start" : "center"
      },
      headerText: {
        color: theme.colors.darkGreyBlue
      },
      contentText: {
        color: theme.colors.greyBlue
      },
      buttonContainer: {
        marginBottom
      }
    };
  }
);

export default PhoneInputScreen;
