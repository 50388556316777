import { makeStyles, useTheme } from "@rneui/themed";
import { FlatList, View } from "react-native";
import { DateTime } from "luxon";

import Feather from "react-native-vector-icons/Feather";

import { getRelativeDateTimeString } from "common/helpers/helpers";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";
import useTextStyles from "../../components/ui/styles/useTextStyles";

const DUMMY_DATA = [
  {
    title: "Title 10 s",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    button: "Button",
    dateTime: DateTime.now().minus({ seconds: 5 }),
    unread: true
  },
  {
    title: "Title 5 min",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    button: "Button",
    dateTime: DateTime.now().minus({ minute: 5 })
  },
  {
    title: "Title 1 h",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    button: "Button",
    dateTime: DateTime.now().minus({ hour: 1 })
  },
  {
    title: "Title 1d",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    button: "Button",
    dateTime: DateTime.now().minus({ day: 1 })
  },
  {
    title: "Title 5 d",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    button: "Button",
    dateTime: DateTime.now().minus({ day: 5 })
  },
  {
    title: "Title 1 mo",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    button: "Button",
    dateTime: DateTime.now().minus({ month: 1 })
  },
  {
    title: "Title 1 y",
    content:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    button: "Button",
    dateTime: DateTime.now().minus({ year: 1 })
  }
];

const ListItem = ({ item }) => {
  const styles = useStyles();
  const { title, content, button, dateTime, unread } = item;
  return (
    <View style={styles.listItemContainer}>
      {unread && <View style={styles.selectedBackground}></View>}
      <View style={styles.row}>
        <Text h4>{title}</Text>
        <Text bodySmall>{getRelativeDateTimeString(dateTime)}</Text>
      </View>

      <Text body>{content}</Text>

      <Button title={button} />
    </View>
  );
};

const ListEmptyComponent = () => {
  const { theme } = useTheme();
  const styles = useStyles();
  const textStyles = useTextStyles();

  return (
    <View style={styles.emptyListContainer}>
      <View style={styles.bellContainer}>
        <Feather name="bell" size={80} color={theme.colors.darkBlue} />
      </View>

      <Text h2 style={textStyles.textAlignCenter}>
        No notifications yet
      </Text>
      <Text body style={textStyles.textAlignCenter}>
        Check again later for reminders and tips to help you use the app.
      </Text>
    </View>
  );
};

const NavigationScreen = () => {
  const styles = useStyles();
  return (
    <ScreenContainer>
      <FlatList
        contentContainerStyle={styles.flatList}
        data={DUMMY_DATA}
        renderItem={({ item }) => <ListItem item={item} />}
        ListEmptyComponent={<ListEmptyComponent />}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    flatList: {
      flexGrow: 1
    },
    emptyListContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      gap: 20
    },
    bellContainer: {
      alignItems: "center",
      justifyContent: "center",
      height: 120,
      width: 120,
      borderRadius: 60,
      borderWidth: 1,
      borderColor: theme.colors.tealBlue
    },
    listItemContainer: {
      padding: 10,
      gap: 10
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },
    selectedBackground: {
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: theme.colors.tealBlue,
      opacity: 0.1
    }
  };
});

export default NavigationScreen;
