export enum OptionTypeEnum {
  THUMBS_BOOLEAN = "thumbs_boolean",
  SINGLE_SELECTION = "single_selection",
  FREE_TEXT = "free_text"
}

export enum FieldTypeEnum {
  THUMBS = "thumbs",
  DROPDOWN = "dropdown",
  IMPROVE_FREETEXT = "improve_freetext"
}

export interface OptionType {
  id: number;
  icon?: string;
  text: string;
  next_data?: DataType[];
}

export interface DataType {
  title?: string;
  subTitle?: string;
  field: FieldTypeEnum;
  type: OptionTypeEnum;
  options: OptionType[];
  submit: boolean;
}

export interface StringMap {
  [key: string]: string;
}

export type HandleOptionsSelectedParams = (
  answer: string,
  field: FieldTypeEnum,
  nextData?: DataType[]
) => void;
