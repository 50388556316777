import React, { useMemo } from "react";
import { View, FlatList } from "react-native";
import { makeStyles } from "@rneui/themed";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import { DateTime } from "luxon";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";

import Spacing from "../../components/ui/Spacing";
import AppointmentCard from "../../components/ui/banners/AppointmentCard";
import Button from "../../components/ui/Button";

import { DashboardStackParamList } from "../../navigation/DashboardStackNavigator";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import { useGetPatientCalendarEventsQuery } from "common/services/CalendarService";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import CalendarEventResponseType from "common/types/Calendaring/CalendarEventResponseType";
import ActivityIndicator from "../../components/ui/ActivityIndicator";

type ScreenProp = NavigationProp<DashboardStackParamList>;

const RenderHeader = () => {
  const textStyles = useTextStyles();
  return (
    <View>
      <Spacing vertical={4} />
      <Text h3 style={textStyles.colorDarkGreyBlue}>
        {LocalizedStrings.screens.myAppointments.text1}
      </Text>
      <Spacing vertical={2} />
      <Text body style={textStyles.colorGreyBlue}>
        {LocalizedStrings.screens.myAppointments.text2}
      </Text>

      <Spacing vertical={4} />
    </View>
  );
};

const RenderItem = ({
  appointment
}: {
  appointment: CalendarEventResponseType;
}) => {
  return <AppointmentCard appointment={appointment} />;
};

const RenderFooter = () => {
  const navigation = useNavigation<ScreenProp>();
  const textStyles = useTextStyles();
  return (
    <>
      <Spacing vertical={4} />
      <Text h4 style={textStyles.colorDarkGreyBlue}>
        {LocalizedStrings.screens.myAppointments.footerText}
      </Text>
      <Spacing vertical={2} />
      <Button
        title={LocalizedStrings.screens.myAppointments.bookAppointment}
        onPress={() => navigation.navigate("YourCareTeam")}
      />
      <Spacing vertical={4} />
    </>
  );
};

const RenderSeparator = () => <Spacing vertical={4} />;

const ListHeaderComponent = () => <RenderHeader />;
const ListFooterComponent = () => <RenderFooter />;

const MyAppointmentsScreen = () => {
  const styles = useStyles();
  const textStyles = useTextStyles();
  const now = useMemo(() => {
    return DateTime.now();
  }, []);

  const { data: member, isLoading: memberLoading } =
    useGetAuthenticatedMember();

  const { data, isLoading: appointmentLoading } =
    useGetPatientCalendarEventsQuery(
      {
        patient_id: member?.patient.patient_id,
        startdate: now.startOf("minute"),
        enddate: now.plus({ days: 89 }).endOf("minute")
      },
      { skip: member === undefined }
    );

  const isLoading = memberLoading || appointmentLoading;

  return (
    <ScreenContainer>
      <FlatList<CalendarEventResponseType>
        data={data}
        keyExtractor={(item, index) => item.event_id}
        contentContainerStyle={styles.container}
        ListHeaderComponent={ListHeaderComponent}
        renderItem={({ item }) => <RenderItem appointment={item} />}
        //ListFooterComponent={ListFooterComponent}
        ItemSeparatorComponent={RenderSeparator}
        ListEmptyComponent={() => {
          return isLoading ? (
            <ActivityIndicator />
          ) : (
            <Text body style={textStyles.colorGreyBlue}>
              {LocalizedStrings.screens.myAppointments.noData}
            </Text>
          );
        }}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginHorizontal: 20
    }
  };
});

export default MyAppointmentsScreen;
