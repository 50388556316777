import { useMemo, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";

import Feather from "react-native-vector-icons/Feather";
import { FlatGrid } from "react-native-super-grid";

import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import useGetMemberDataSummary, {
  DeviceReadingSummaryType,
  ReadingAccessorType
} from "common/hooks/useGetMemberDataSummary";

import ScreenContainer from "../../components/ui/ScreenContainer";
import useViewStyles from "../../components/ui/styles/useViewStyles";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";
import Spacing from "../../components/ui/Spacing";
import Chart from "../../components/readings/Chart";
import Chip from "../../components/ui/Chip";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import RefreshControl from "../../components/ui/RefreshControl";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import { useGetLatestReadingsByMemberQuery } from "common/services/ReadingsService";
import DeviceTrendParam from "common/enums/DeviceTrendParamEnum";
import PhoneCallComponent from "../../components/ui/PhoneCallComponent";
import LocalizedStrings from "../../helpers/LocalizedStrings";

interface ReadingSummaryProps {
  type: DeviceTypeEnum;
  days: number;
  trendPercent: number;
  data: DeviceReadingSummaryType;
  onPress: () => void;
}

const ReadingSummary = ({
  type,
  days,
  trendPercent,
  data,
  onPress
}: ReadingSummaryProps) => {
  const { theme } = useTheme();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();
  const styles = useStyles();

  const { type: screenType } = useScreenType();

  const icon = type === DeviceTypeEnum.BLOOD_PRESSURE ? "activity" : "droplet";
  const header =
    type === DeviceTypeEnum.BLOOD_PRESSURE
      ? LocalizedStrings.common.bloodPressure.toUpperCase()
      : LocalizedStrings.common.bloodSugar.toUpperCase();

  let chipText, chipIcon, chipColor;
  if (trendPercent > 0) {
    chipText = LocalizedStrings.screens.myReadings.increase;
    chipColor = "#44F46024";
    chipIcon = "arrow-up";
  } else if (trendPercent < 0) {
    chipText = LocalizedStrings.screens.myReadings.decrease;
    chipColor = "#F4444424";
    chipIcon = "arrow-down";
  } else if (trendPercent === 0) {
    chipText = LocalizedStrings.screens.myReadings.noChange;
    chipColor = "#F4444424";
    chipIcon = "";
  }

  const accessor =
    type === DeviceTypeEnum.BLOOD_PRESSURE
      ? ["systolic", "diastolic"]
      : ["glucose"];

  const [viewWidth, setViewWidth] = useState<number>();

  const onLayout = (event: LayoutChangeEvent) => {
    const width = event.nativeEvent.layout.width;
    setViewWidth(width);
  };

  return (
    <View style={viewStyles.cardContainer} onLayout={onLayout}>
      <View style={styles.row}>
        <Feather name={icon} color={theme.colors.tealBlue} size={24} />
        <Text capsSmall style={textStyles.colorDarkBlue}>
          {header}
        </Text>
      </View>

      <Text bodySmall style={textStyles.colorDarkBlue}>
        {LocalizedStrings.screens.myReadings.overTheLastDays.replace(
          "{{DAYS}}",
          "" + days
        )}
      </Text>

      {data.data.length > 1 && trendPercent !== undefined && (
        <View style={styles.row}>
          <Text largeTitle style={textStyles.colorDarkBlue}>{`${Math.round(
            trendPercent
          )}%`}</Text>
          <Chip title={chipText} color={chipColor} iconName={chipIcon} />
        </View>
      )}

      {data && (
        <Chart
          data={data?.data}
          hasAxis={false}
          width={viewWidth}
          height={screenType === ScreenTypeEnum.PHONE ? 250 : 300}
          accessors={accessor as ReadingAccessorType[]}
          accessorsMargin={{ x: 30, y: 45 }}
        />
      )}

      <Button
        title={LocalizedStrings.screens.myReadings.viewProgress}
        onPress={onPress}
      />
    </View>
  );
};

const MyReadingsScreen = ({ navigation }) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  const { data: patient, isLoading: isPatientLoading } =
    useGetAuthenticatedMember();

  const {
    bloodPressure,
    glucose,
    trends,
    isLoading: isPatientDataLoading,
    isFetching,
    refetch
  } = useGetMemberDataSummary({
    patient,
    days: 30,
    fetchAPIs: [DeviceTypeEnum.BLOOD_PRESSURE, DeviceTypeEnum.GLUCOSE_CATEGORY]
  });

  const { data: latestReadings, isLoading: isLatestLoading } =
    useGetLatestReadingsByMemberQuery(
      {
        memberId: patient?.patient?.patient_id,
        devices: [DeviceTrendParam.GLUCOSE, DeviceTrendParam.BLOOD_PRESSURE]
      },
      { skip: patient?.patient?.patient_id === undefined }
    );

  const handleRefresh = () => {
    refetch();
  };

  const hasData =
    latestReadings?.blood_pressure !== undefined ||
    latestReadings?.glucose !== undefined;

  const glucoseTrendPercent = trends?.data?.glucose?.[30]?.trend;
  const bloodTrendPercent = trends?.data?.blood_pressure?.[30]?.trend;

  const gridData = useMemo(() => {
    const gridData = [];

    if (glucose?.isSuccess) {
      gridData.push({
        type: DeviceTypeEnum.GLUCOSE_CATEGORY,
        data: glucose,
        trendPercent: glucoseTrendPercent,
        onPress: () => navigation.navigate("GlucoseReadingDetails")
      });
    }

    if (bloodPressure?.isSuccess) {
      gridData.push({
        type: DeviceTypeEnum.BLOOD_PRESSURE,
        data: bloodPressure,
        trendPercent: bloodTrendPercent,
        onPress: () => navigation.navigate("BloodReadingDetails")
      });
    }

    return gridData;
  }, [bloodPressure, glucose]);

  const isLoading = isPatientLoading || isPatientDataLoading || isLatestLoading;
  if (isLoading)
    return (
      <ScreenContainer innerStyle={styles.center}>
        <ActivityIndicator />
      </ScreenContainer>
    );

  return (
    <ScreenContainer>
      {hasData ? (
        <FlatGrid
          ListHeaderComponent={() => (
            <Text h3 style={[textStyles.colorDarkGreyBlue, styles.textMargin]}>
              {LocalizedStrings.screens.myReadings.header}
            </Text>
          )}
          style={styles.container}
          refreshControl={
            <RefreshControl refreshing={isFetching} onRefresh={handleRefresh} />
          }
          ListFooterComponent={() => <Spacing vertical={5} />}
          itemDimension={300}
          data={gridData}
          renderItem={({ item }) => (
            <ReadingSummary
              type={item.type}
              days={30}
              data={item.data}
              trendPercent={item.trendPercent}
              onPress={item.onPress}
            />
          )}
        />
      ) : (
        <View style={styles.noReadingsContainer}>
          <Text
            h3
            accessibilityLabel={"You have not taken any readings yet"}
            style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
          >
            {LocalizedStrings.screens.myReadings.noReadingsYet}
          </Text>

          <Spacing vertical={4} />

          <Text
            body
            style={[textStyles.colorDarkBlue, textStyles.textAlignCenter]}
          >
            {LocalizedStrings.screens.myReadings.onceYouTakeReading}
          </Text>

          <Spacing vertical={2} />

          <Text
            body
            accessibilityLabel={"Description Readings with No Readings"}
            style={[textStyles.colorDarkBlue, textStyles.textAlignCenter]}
          >
            {LocalizedStrings.screens.myReadings.needGuidance}
          </Text>

          <Spacing vertical={2} />

          <PhoneCallComponent />
        </View>
      )}
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      gap: 20
    },
    noReadingsContainer: {
      flex: 1,
      gap: 5,
      margin: 20,
      alignItems: "center",
      justifyContent: "center"
    },
    textMargin: {
      marginHorizontal: 20,
      marginBottom: 20
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      gap: 10
    },
    center: {
      justifyContent: "center",
      alignItems: "center"
    }
  };
});

export default MyReadingsScreen;
