import React, { View, ScrollView, ActivityIndicator } from "react-native";
import { Divider, makeStyles } from "@rneui/themed";

import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import { getAddress, maskPhoneNumber } from "common/helpers/helpers";

import PatientHeader from "../../components/myaccount/PatientHeader";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Button from "../../components/ui/Button";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import { MyAccountStackParamList } from "../../navigation/MyAccountStackNavigator";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const ListItem = ({ testID, title, value }) => {
  const styles = useStyles();
  return (
    <View
      style={styles.listItemContainer}
      {...AccessibilityHelper_getAccessibilityProps(value)}
    >
      <Text testID={testID + "_title"} capsSmall style={styles.textBlack}>
        {title.toUpperCase()}
      </Text>
      <Text
        {...AccessibilityHelper_getAccessibilityProps(testID)}
        testID={testID + "_value"}
        bodySmall
        style={styles.textGreyBlue}
      >
        {value}
      </Text>
    </View>
  );
};

const PersonalInfoScreen = () => {
  const navigation = useNavigation<NavigationProp<MyAccountStackParamList>>();

  const styles = useStyles();
  const { type } = useScreenType();

  const { data: patient, isLoading } = useGetAuthenticatedMember();

  const { first, last, phone, mobile, address, contact_info } =
    patient?.patient || {};

  return (
    <ScreenContainer>
      {isLoading ? (
        <ActivityIndicator style={styles.flex1} />
      ) : (
        <ScrollView style={styles.flex1}>
          <Spacing vertical={2} />
          <View style={styles.row}>
            <View style={styles.flex1}>
              <PatientHeader />
            </View>

            {type !== ScreenTypeEnum.PHONE && (
              <Button
                type="outline"
                title={LocalizedStrings.screens.personalInformation.button}
                accessibilityLabel={"Update Personal Information"}
                containerStyle={styles.buttonContainer}
                onPress={() => navigation.navigate("EditPersonalInfo")}
              />
            )}
          </View>

          <Spacing vertical={3} />

          <View
            style={
              type !== ScreenTypeEnum.PHONE ? styles.tabletInputContainer : {}
            }
          >
            <ListItem
              testID={"first_name"}
              title={LocalizedStrings.screens.personalInformation.firstName}
              value={first}
            />

            {type !== ScreenTypeEnum.PHONE && <Divider />}

            <ListItem
              testID={"last_name"}
              title={LocalizedStrings.screens.personalInformation.lastName}
              value={last}
            />
            {type !== ScreenTypeEnum.PHONE && contact_info?.email?.value && (
              <Divider />
            )}
            {contact_info?.email?.value && (
              <ListItem
                testID={"email"}
                title={LocalizedStrings.screens.personalInformation.emailAddress.toUpperCase()}
                value={contact_info?.email?.value ?? ""}
              />
            )}
            {type !== ScreenTypeEnum.PHONE && <Divider />}
            <ListItem
              testID={"phone"}
              title={LocalizedStrings.screens.personalInformation.phoneNumber}
              value={maskPhoneNumber(
                contact_info?.landline?.value ?? phone ?? ""
              )}
            />
            {type !== ScreenTypeEnum.PHONE && <Divider />}
            <ListItem
              testID={"mobile"}
              title={LocalizedStrings.screens.personalInformation.mobileNumber}
              value={maskPhoneNumber(
                contact_info?.mobile?.value ?? mobile ?? ""
              )}
            />

            {address != undefined && (
              <>
                {type !== ScreenTypeEnum.PHONE && <Divider />}
                <ListItem
                  testID={"address"}
                  title={LocalizedStrings.screens.personalInformation.address}
                  value={getAddress(address)}
                />
                {type !== ScreenTypeEnum.PHONE && <Divider />}
                <ListItem
                  testID={"city"}
                  title={LocalizedStrings.screens.personalInformation.city}
                  value={address?.city}
                />
                {type !== ScreenTypeEnum.PHONE && <Divider />}
                <ListItem
                  testID={"zip"}
                  title={LocalizedStrings.screens.personalInformation.zipCode}
                  value={address?.postal_code}
                />
                {type !== ScreenTypeEnum.PHONE && <Divider />}
                <ListItem
                  testID={"state"}
                  title={LocalizedStrings.screens.personalInformation.state}
                  value={address?.state}
                />
              </>
            )}
          </View>
        </ScrollView>
      )}
      {type === ScreenTypeEnum.PHONE && (
        <Button
          title={LocalizedStrings.screens.personalInformation.button}
          accessibilityLabel={"Update Personal Information"}
          containerStyle={styles.buttonContainer}
          onPress={() => navigation.navigate("EditPersonalInfo")}
        />
      )}
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    flex1: {
      flex: 1
    },
    row: {
      flexDirection: "row",
      alignItems: "center"
    },
    listItemContainer: {
      marginHorizontal: 20,
      marginVertical: 10
    },
    tabletInputContainer: {
      justifyContent: "center",
      borderRadius: 20,
      borderWidth: 1,
      borderColor: theme.colors.grey4,
      marginHorizontal: 20,
      overflow: "hidden"
    },
    buttonContainer: {
      marginTop: 10,
      margin: 20
    },
    textBlack: {
      color: theme.colors.black
    },
    textGreyBlue: {
      color: theme.colors.greyBlue
    }
  };
});

export default PersonalInfoScreen;
