import { Platform, useWindowDimensions, View } from "react-native";

import { DateTime } from "luxon";
import { makeStyles, useTheme } from "@rneui/themed";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";

import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import { ReadingAccessorType } from "common/hooks/useGetMemberDataSummary";

import Chart from "../../components/readings/Chart";
import IconButton from "../../components/ui/IconButton";
import { useGetReadingsByMemberQuery } from "common/services/ReadingsService";

import useScreenType, {
  ScreenOrientationTypeEnum,
  ScreenTypeEnum
} from "../../hooks/useScreenType";
import ScreenContainer from "../../components/ui/ScreenContainer";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import RotateDevice from "../../components/ui/navigation/RotateDevice";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";
import Spacing from "../../components/ui/Spacing";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import { DeviceType_url_decode } from "common/helpers/helpers";
import { useHideTabBar } from "../../navigation/NavigationUtils";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import { getNavigationBarHeight } from "../../helpers/getAndroidNavBarHeightHelper";

const ChartFullModal = ({ navigation, route }) => {
  const { theme } = useTheme();
  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);
  const textStyles = useTextStyles();
  const { width, height } = useWindowDimensions();

  const { type, orientation } = useScreenType();

  const { data: patient, isLoading: isPatientLoading } =
    useGetAuthenticatedMember();

  const timezone = patient?.patient?.timezone ?? "local";

  let startdate, enddate;
  enddate = Math.round(
    DateTime.now().setZone(timezone).endOf("day").toSeconds()
  );

  const { readingType: readingTypeEncoded, days } = route.params;
  const readingType = DeviceType_url_decode(readingTypeEncoded);

  if (days !== undefined) {
    startdate = DateTime.now()
      .minus({ days })
      .setZone(timezone)
      .startOf("day")
      .toSeconds();
  }

  const accessor =
    readingType === DeviceTypeEnum.BLOOD_PRESSURE
      ? ["systolic", "diastolic"]
      : ["glucose"];

  const header =
    readingType === DeviceTypeEnum.BLOOD_PRESSURE
      ? `${LocalizedStrings.common.bloodPressure} (${LocalizedStrings.common.units.mmHg})`
      : `${LocalizedStrings.common.glucoseReadings} (${LocalizedStrings.common.units.glucoseUnit})`;

  const unit =
    readingType === DeviceTypeEnum.BLOOD_PRESSURE
      ? LocalizedStrings.common.units.mmHg
      : LocalizedStrings.common.units.glucoseUnit;

  const {
    data,
    isLoading: isDataLoading,
    isError,
    isSuccess,
    refetch
  } = useGetReadingsByMemberQuery(
    {
      memberId: patient ? patient.patient.patient_id : "",
      paramsObject: {
        device_type: readingType,
        sort: "measure_timestamp,ASC",
        startdate,
        enddate
      }
    },
    {
      skip: patient === undefined
    }
  );

  useHideTabBar(navigation, type !== ScreenTypeEnum.PHONE);

  const isLoading = isPatientLoading || isDataLoading;

  const hasToShowChart =
    orientation === ScreenOrientationTypeEnum.LANDSCAPE ||
    type !== ScreenTypeEnum.PHONE;

  const insetsBottom =
    Platform.OS === "android"
      ? Math.max(insets.bottom, getNavigationBarHeight(), 30)
      : insets.bottom;

  return (
    <View style={styles.modal}>
      <ScreenContainer>
        {isLoading && (
          <View style={styles.loadingContainer}>
            <ActivityIndicator />
          </View>
        )}
        {/* Make sure it is landscape or big screen) because of flickering chart because of screen orientation change on Android.*/}
        {isSuccess && (
          <>
            {hasToShowChart ? (
              <View style={styles.chartContainer} accessibilityLabel={header}>
                <Chart
                  data={data}
                  hasAxis={true}
                  width={width - insets.right}
                  height={height - insetsBottom}
                  accessors={accessor as ReadingAccessorType[]}
                  zoomEnabled
                  header={header}
                  unit={unit}
                />
              </View>
            ) : (
              <RotateDevice />
            )}
          </>
        )}

        {!isLoading && isError && (
          <View style={styles.loadingContainer}>
            <Text
              body
              style={[textStyles.textAlignCenter, textStyles.colorDarkGreyBlue]}
            >
              {LocalizedStrings.screens.readingDetails.error}
            </Text>
            <Spacing vertical={2} />
            <Button
              type="outline"
              onPress={() => refetch()}
              title={LocalizedStrings.common.tryAgain}
            />
          </View>
        )}

        <IconButton
          icon="x"
          shape="square"
          iconColor={theme.colors.white}
          color={theme.colors.tealBlue}
          onPress={() => navigation.goBack()}
          style={styles.closeButton}
        />
      </ScreenContainer>
    </View>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  return {
    modal: {
      flex: 1
    },
    closeButton: {
      position: "absolute",
      top: 20 + insets.top,
      right: 20 + insets.right,
      backgroundColor: theme.colors.tealBlue
    },
    loadingContainer: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center"
    },
    chartContainer: {
      flex: 1,
      margin: 20,
      marginLeft: Math.max(20, insets.left + 5)
    }
  };
});

export default ChartFullModal;
