import { useCallback, useEffect, useState } from "react";
import { Platform, View, Keyboard } from "react-native";
import { makeStyles } from "@rneui/themed";
import Toast from "react-native-toast-message";
import { useSelector } from "react-redux";

import { DateTime } from "luxon";

import { AuthService_RequestSMSCode } from "common/services/AuthService";
import { RootState, useAppDispatch } from "common/redux";
import { logInWithSMS } from "common/redux/AuthSlice";

import Button from "../../components/ui/Button";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import StepIndicator from "../../components/ui/StepIndicator";
import ConfirmationCodeInput from "../../components/ui/ConfirmationCodeInput";
import Spacing from "../../components/ui/Spacing";
import { maskPhoneNumber } from "common/helpers/helpers";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import { useFocusEffect } from "@react-navigation/native";
import { Alert_show } from "common/helpers/AlertHelper";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import LocalizedStringsCommon from "common/localizations/LocalizedStrings";

function getErrorMessage(statusCode: number) {
  switch (statusCode) {
    case 403:
      return LocalizedStringsCommon.error.sms_code;
    default:
      return LocalizedStringsCommon.error.default;
  }
}

const SMSCodeScreen = ({ route, navigation }) => {
  const dispatch = useAppDispatch();
  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);

  const { hasCredentials, isLoading } = useSelector(
    (state: RootState) => state.auth
  );

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const { data: patient } = useGetAuthenticatedMember(!isFocused);

  const [resendCodeLoading, setResendCodeLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>("");
  const [newPreLoginId, setPreLoginId] = useState<string>();

  const [countDownTargetDate, setCountDownTargetDate] = useState<DateTime>();
  const [secondsRemaining, setSecondsRemaining] = useState<number>(0);

  const { preLoginId, phoneNumber, birthDate } = route.params;

  function smsCleanup() {
    setCode("");
    setPreLoginId(undefined);
    setSecondsRemaining(0);
    setCountDownTargetDate(undefined);
  }

  useFocusEffect(
    useCallback(() => {
      // Do something when the screen is focused
      setIsFocused(true);

      return () => {
        // Do something when the screen is unfocused
        // Useful for cleanup functions
        setIsFocused(false);
        smsCleanup();
      };
    }, [])
  );

  useEffect(() => {
    // reset code on initial mount
    setCode("");
    return () => {
      smsCleanup();
    };
  }, []);

  const startCountdown = () => {
    const targetDate = DateTime.now().plus({ seconds: 60 });
    setSecondsRemaining(
      Math.round(targetDate.diff(DateTime.now(), "seconds").seconds)
    );
    setCountDownTargetDate(targetDate);
  };

  useEffect(() => {
    if (hasCredentials && patient) {
      navigation.reset({
        index: 0,
        routes: [{ name: "MissingLegalForms" }]
      });

      smsCleanup();
    }
  }, [hasCredentials, navigation, phoneNumber, patient]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDownTargetDate === undefined) return;

      setSecondsRemaining(
        Math.round(countDownTargetDate.diff(DateTime.now(), "seconds").seconds)
      );
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [countDownTargetDate]);

  useEffect(() => {
    if (code?.length >= 6) {
      Keyboard.dismiss();
      verifyCodeHandler();
    }
  }, [code]);

  const requestCode = () => {
    return new Promise<string>((resolve) => {
      const dateObject = DateTime.fromISO(birthDate);
      setResendCodeLoading(true);
      AuthService_RequestSMSCode(dateObject, phoneNumber)
        .then((response) => {
          const { pre_login_id } = response.data;

          setPreLoginId(pre_login_id);
          setResendCodeLoading(false);
          startCountdown();
          resolve("OK");
        })
        .catch((err) => {
          const status = err?.response?.status;
          const errMessage = getErrorMessage(status);
          setResendCodeLoading(false);

          Alert_show({
            dispatch,
            title: LocalizedStringsCommon.error.title,
            content: errMessage,
            type: "error"
          });
        });
    });
  };

  const verifyCodeHandler = () => {
    dispatch(
      logInWithSMS({
        pre_login_id: newPreLoginId ?? preLoginId,
        code
      })
    );
  };

  const resendCodeHandler = () => {
    requestCode().then(() => {
      Toast.show({
        type: "info",
        text1: LocalizedStrings.screens.smsCode.codeSent,
        position: "bottom",
        bottomOffset: 300
      });
    });
  };

  return (
    <ScreenContainer
      innerStyle={styles.container}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <StepIndicator size={5} index={1} />
      <Spacing vertical={2} />
      <View style={styles.formContainer}>
        <Text bodyLarge style={styles.contentText}>
          {LocalizedStrings.screens.smsCode.text.replace(
            "{{PHONE_NUMBER}}",
            maskPhoneNumber(phoneNumber)
          )}
        </Text>

        <ConfirmationCodeInput
          title={LocalizedStrings.screens.smsCode.inputTitle}
          length={6}
          value={code}
          onValueChange={(text) => setCode(text)}
        />
      </View>

      <Spacing vertical={2} />

      <Button
        title={LocalizedStrings.common.submit}
        accessibilityLabel={"Submit"}
        disabled={code.length < 6 || preLoginId === undefined}
        loading={isLoading}
        onPress={verifyCodeHandler}
      />

      <Spacing vertical={2} />
      <Button
        title={
          secondsRemaining > 0
            ? LocalizedStrings.screens.smsCode.secondsRemaining.replace(
                "{{SECONDS}}",
                "" + secondsRemaining
              )
            : LocalizedStrings.screens.smsCode.resendCode
        }
        accessibilityLabel={"Resend Code"}
        disabled={secondsRemaining > 0}
        loading={resendCodeLoading}
        type="outline"
        containerStyle={styles.buttonContainer}
        onPress={resendCodeHandler}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  const bottomInsets = insets.bottom + (Platform.OS === "android" ? 10 : 0);
  const marginBottom = Math.max(bottomInsets, 10);

  return {
    container: {
      flex: 1,
      marginHorizontal: 10
    },
    formContainer: {
      flex: 1,
      gap: 20,
      justifyContent: "center"
    },
    contentText: {
      color: theme.colors.greyBlue
    },
    errorText: {
      color: theme.colors.error
    },
    buttonContainer: {
      marginBottom
    }
  };
});

export default SMSCodeScreen;
