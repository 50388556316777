import { View } from "react-native";
import Text from "../../components/ui/Text";
import Input from "../../components/ui/Input";
import LocalizedStrings from "../../helpers/LocalizedStrings";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import { makeStyles } from "@rneui/themed";
import Spacing from "../../components/ui/Spacing";
import Button from "../../components/ui/Button";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useEffect, useState } from "react";
import ScreenContainer from "../../components/ui/ScreenContainer";

const TEXT_LENGTH = 6;

const JoinVideoCallScreen = ({ navigation }) => {
  const textStyles = useTextStyles();
  const styles = useStyles();
  const insets = useSafeAreaInsets();

  const [code, setCode] = useState<string>("");

  useEffect(() => {
    if (code.length === TEXT_LENGTH) onSubmit();
  }, [code]);

  const onSubmit = () => {
    navigation.navigate("VideoCall", { code });
  };

  return (
    <ScreenContainer
      innerStyle={styles.container}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <View style={styles.flex1}>
        <Text
          body
          style={[textStyles.colorGreyBlue, textStyles.textAlignCenter]}
        >
          {LocalizedStrings.screens.joinVideoCall.header}
        </Text>
        <Spacing vertical={2} />
        <Text
          body
          style={[textStyles.colorGreyBlue, textStyles.textAlignCenter]}
        >
          {LocalizedStrings.screens.joinVideoCall.enterCode}
        </Text>
        <Spacing vertical={4} />
        <Input
          label={LocalizedStrings.screens.joinVideoCall.placeholder}
          placeholder={LocalizedStrings.screens.joinVideoCall.placeholder}
          maxLength={TEXT_LENGTH}
          value={code}
          autoCapitalize="characters"
          onChangeText={(text) => setCode(text.toUpperCase())}
        />
      </View>

      <Button
        title={LocalizedStrings.screens.joinVideoCall.joinCall}
        containerStyle={{ marginBottom: Math.max(insets.bottom, 10) }}
        onPress={onSubmit}
        disabled={code.length !== TEXT_LENGTH}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: 40
    },
    flex1: {
      flex: 1,
      alignItems: "center"
    }
  };
});

export default JoinVideoCallScreen;
