import React from "react";
import { View, FlatList } from "react-native";
import { makeStyles } from "@rneui/themed";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Button from "../../components/ui/Button";
import Spacing from "../../components/ui/Spacing";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const DATA = [
  "Category A",
  "Category B",
  "Category C",
  "Category D",
  "Category E",
  "Category F"
];

const ListHeader = () => {
  const textStyles = useTextStyles();
  return (
    <View>
      <Text h2 style={textStyles.colorDarkGreyBlue}>
        {LocalizedStrings.screens.goals.selectCategory}
      </Text>
      <Spacing vertical={4} />
    </View>
  );
};

const ItemSeparator = () => <Spacing vertical={4} />;

const AddGoalCategoryScreen = ({ navigation }) => {
  const styles = useStyles();
  return (
    <ScreenContainer>
      <View style={styles.container}>
        <FlatList
          data={DATA}
          renderItem={({ item }) => (
            <Button
              type="outline"
              title={item}
              onPress={() => navigation.navigate("AddGoal")}
            />
          )}
          ListHeaderComponent={ListHeader}
          ItemSeparatorComponent={ItemSeparator}
        />
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      margin: 20
    }
  };
});

export default AddGoalCategoryScreen;
