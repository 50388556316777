import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect
} from "react";
import { Platform, View } from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";

import Feather from "react-native-vector-icons/Feather";
import {
  Bubble,
  GiftedChat,
  IMessage,
  MessageProps,
  SendProps,
  ComposerProps,
  Composer
} from "react-native-gifted-chat";

import ScreenContainer from "../../components/ui/ScreenContainer";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import TouchableOpacity from "../../components/ui/TouchableOpacity";

const RenderSend = (props: SendProps<IMessage>) => {
  const styles = useStyles();
  const { theme } = useTheme();

  return (
    <TouchableOpacity
      style={styles.sendContainer}
      disabled={props.text.length === 0}
      onPress={() => props.onSend([{ text: props.text }], true)}
    >
      <Feather name="send" color={theme.colors.darkGreyBlue} size={24} />
    </TouchableOpacity>
  );
};

const RenderBubble = (props: Readonly<MessageProps<IMessage>>) => {
  const { theme } = useTheme();
  return (
    <Bubble
      {...props}
      textStyle={{
        left: {
          color: theme.colors.darkBlue
        },
        right: {
          color: theme.colors.white
        }
      }}
      wrapperStyle={{
        left: {
          backgroundColor: theme.colors.lightGreen
        },
        right: {
          backgroundColor: theme.colors.tealBlue
        }
      }}
    />
  );
};

const RenderChatEmpty = () => {
  const styles = useStyles();
  const textStyles = useTextStyles();
  return (
    <View style={styles.emptyContainer}>
      <Text
        h2
        style={[textStyles.colorDarkGreyBlue, textStyles.textAlignCenter]}
      >
        Start a conversation with Mindy Smith
      </Text>
      <Spacing vertical={4} />
      <Text
        bodyLarge
        style={[textStyles.colorGreyBlue, textStyles.textAlignCenter]}
      >
        You can review your doubts, request information or discuss any problems
        you may face.
      </Text>
    </View>
  );
};

export const RenderComposer = (
  props: ComposerProps & {
    onSend: SendProps<IMessage>["onSend"];
    text: SendProps<IMessage>["text"];
  }
) => {
  return (
    <Composer
      {...props}
      textInputProps={{
        ...props.textInputProps,
        // for enabling the Return key to send a message only on web
        blurOnSubmit: Platform.OS === "web",
        onSubmitEditing:
          Platform.OS === "web"
            ? () => {
                if (props.text && props.onSend) {
                  props.onSend({ text: props.text.trim() }, true);
                }
              }
            : undefined
      }}
    />
  );
};

const ChatScreen = ({ navigation }) => {
  const textStyles = useTextStyles();

  const [messages, setMessages] = useState<IMessage[]>([]);

  useEffect(() => {
    setMessages([
      {
        _id: 1,
        text: "Hello, how can I help you?",
        createdAt: new Date(),
        user: {
          _id: 2,
          name: "Mindy Smith",
          avatar: "https://www.w3schools.com/howto/img_avatar.png"
        }
      }
    ]);
  }, []);
  //Message Mindy Smith
  const onSend = useCallback((messages = []) => {
    setMessages((previousMessages) =>
      GiftedChat.append(previousMessages, messages)
    );
  }, []);

  useLayoutEffect(() => {
    navigation.setOptions({ title: "Message Mindy Smith" });
  }, []);

  return (
    <ScreenContainer>
      <GiftedChat
        renderChatEmpty={RenderChatEmpty}
        renderBubble={(props: Readonly<MessageProps<IMessage>>) => (
          <RenderBubble {...props} />
        )}
        renderComposer={RenderComposer}
        renderSend={RenderSend}
        // @ts-ignore
        textInputStyle={[textStyles.body, textStyles.colorDarkGreyBlue]}
        messages={messages}
        onSend={(messages) => onSend(messages)}
        user={{
          _id: 1
        }}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    sendContainer: {
      marginRight: 20,
      alignSelf: "center"
    },
    emptyContainer: {
      flex: 1,
      marginHorizontal: 20,
      alignItems: "center",
      justifyContent: "center",
      // https://github.com/facebook/react-native/issues/21196
      transform: [{ scaleY: -1 }]
    }
  };
});

export default ChatScreen;
