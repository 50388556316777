import { useState } from "react";
import { makeStyles, useTheme } from "@rneui/themed";
import { ScrollView, View, LayoutChangeEvent } from "react-native";

import { DateTime } from "luxon";
import Feather from "react-native-vector-icons/Feather";

import ScreenContainer from "../../components/ui/ScreenContainer";
import useViewStyles from "../../components/ui/styles/useViewStyles";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import Chip from "../../components/ui/Chip";
import SegmentedControl from "../../components/ui/SegmentedControl";
import Chart from "../../components/readings/Chart";
import Button from "../../components/ui/Button";
import DeviceTypeEnum from "common/enums/DeviceTypeEnum";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import useGetMemberDataSummary, {
  ReadingAccessorType
} from "common/hooks/useGetMemberDataSummary";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import { ReadingQualifierReasonEnum_toString } from "common/enums/ReadingQualifierReasonEnum";
import { ReadingsStackParamList } from "../../navigation/ReadingsStackNavigator";
import {
  NavigationProp,
  RouteProp,
  useNavigation,
  useRoute
} from "@react-navigation/native";
import RefreshControl from "../../components/ui/RefreshControl";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import { AccessibilityHelper_getAccessibilityProps } from "../../helpers/AccessibilityHelper";
import {
  DeviceType_url_decode,
  DeviceType_url_encode
} from "common/helpers/helpers";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const CHART_OPTIONS = [
  LocalizedStrings.common.week,
  "30 d",
  "90 d",
  LocalizedStrings.common.year
];
const CHART_OPTIONS_MINUS = [7, 30, 90, 365];

interface IProps {
  title: string;
  value: number | string;
  subValue?: number | string;
}

const RenderMeasurement = ({ title, value, subValue }: IProps) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  return (
    <View style={styles.alignItemsCenter}>
      <Text
        allowFontScaling={false}
        testID={"test-measurement-" + title}
        h4
        style={textStyles.colorDarkBlue}
      >
        {value}
        {subValue !== undefined && !Number.isNaN(subValue) && (
          <Text
            testID={"test-subvalue-measurement-" + title}
            bodySmall
            style={textStyles.colorDarkBlue}
          >
            {" "}
            /{subValue}
          </Text>
        )}
      </Text>
      <Text bodySmall style={textStyles.colorDarkBlue}>
        {title}
      </Text>
    </View>
  );
};

const ReadingsDetailsScreen = () => {
  const navigation = useNavigation<NavigationProp<ReadingsStackParamList>>();
  const route =
    useRoute<RouteProp<ReadingsStackParamList, "BloodReadingDetails">>();

  const { theme } = useTheme();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();
  const styles = useStyles();

  const { type } = useScreenType();

  const { readingType: readingTypeEncoded } = route.params;
  const readingType = DeviceType_url_decode(readingTypeEncoded);

  const [chartIndex, setChartIndex] = useState<number>(0);
  const [viewWidth, setViewWidth] = useState<number>();

  const { data: patient } = useGetAuthenticatedMember();

  const { bloodPressure, glucose, isLoading, isFetching, trends, refetch } =
    useGetMemberDataSummary({
      patient,
      days: CHART_OPTIONS_MINUS[chartIndex],
      fetchAPIs: [readingType],
      sort: "DESC",
      getTrends: true
    });

  const handleRefresh = () => {
    refetch();
  };

  const accessor =
    readingType === DeviceTypeEnum.BLOOD_PRESSURE
      ? ["systolic", "diastolic"]
      : ["glucose"];
  const unit =
    readingType === DeviceTypeEnum.BLOOD_PRESSURE
      ? LocalizedStrings.common.units.mmHg
      : LocalizedStrings.common.units.glucoseUnit;
  const data =
    readingType === DeviceTypeEnum.BLOOD_PRESSURE ? bloodPressure : glucose;

  const readingQualifierString = ReadingQualifierReasonEnum_toString(
    data?.lastReading?.reading_qualifier
  )?.toUpperCase();

  const onLayout = (event: LayoutChangeEvent) => {
    const viewWidth = event.nativeEvent.layout.width;
    setViewWidth(viewWidth);
  };

  const trendPercent =
    readingType === DeviceTypeEnum.BLOOD_PRESSURE
      ? trends?.data?.blood_pressure?.trends?.[30]?.trend
      : trends?.data?.glucose?.trends?.[30]?.trend;
  const chipIcon = trendPercent > 0 ? "arrow-up" : "arrow-down";
  const chipColor = trendPercent > 0 ? "#44F46024" : "#F4444424";

  return (
    <ScreenContainer>
      <ScrollView
        refreshControl={
          <RefreshControl
            refreshing={isLoading === false && isFetching}
            onRefresh={handleRefresh}
          />
        }
      >
        <View style={styles.container}>
          {data.lastReading && (
            <View
              style={[
                viewStyles.cardContainer,
                styles.row,
                styles.alignItemsCenter
              ]}
            >
              <View
                style={[
                  styles.flex1,
                  type === ScreenTypeEnum.PHONE
                    ? styles.alignItemsCenter
                    : styles.alignItemsStart,
                  styles.gap
                ]}
              >
                <Text capsSmall style={textStyles.colorDarkGreyBlue}>
                  {LocalizedStrings.screens.readingDetails.latestReading.toUpperCase()}
                </Text>

                {readingType === DeviceTypeEnum.BLOOD_PRESSURE && (
                  <View
                    style={[
                      type === ScreenTypeEnum.PHONE
                        ? [styles.alignItemsCenter, styles.stretch]
                        : styles.gap
                    ]}
                  >
                    <Text
                      largeTitle
                      style={textStyles.colorDarkBlue}
                      allowFontScaling={false}
                      {...AccessibilityHelper_getAccessibilityProps(
                        "text-blood-pressure-systolic"
                      )}
                    >
                      {data.lastReading.systolic}
                      {"/"}
                      {data.lastReading.diastolic}{" "}
                      <Text bodySmall style={textStyles.colorDarkBlue}>
                        {LocalizedStrings.common.units.mmHg}
                      </Text>
                    </Text>

                    <Text
                      largeTitle
                      style={textStyles.colorDarkBlue}
                      allowFontScaling={false}
                      testID="text-blood-pressure-bpm"
                    >
                      {data.lastReading.pulse}{" "}
                      <Text
                        bodySmall
                        testID="text-blood-pressure-bpm-unit"
                        style={textStyles.colorDarkBlue}
                      >
                        bpm
                      </Text>
                    </Text>
                  </View>
                )}

                {readingType === DeviceTypeEnum.GLUCOSE_CATEGORY && (
                  <Text
                    {...AccessibilityHelper_getAccessibilityProps(
                      "text-glucose"
                    )}
                    largeTitle
                    style={textStyles.colorDarkBlue}
                  >
                    {data.lastReading.glucose}{" "}
                    <Text testID="text-glucose-unit" bodySmall>
                      {LocalizedStrings.common.units.glucoseUnit}
                    </Text>
                  </Text>
                )}

                {type === ScreenTypeEnum.PHONE ? (
                  <Text
                    testID="test-measure-timestamp"
                    bodySmall
                    style={textStyles.colorDarkBlue}
                  >
                    {DateTime.fromSeconds(
                      data.lastReading.measure_timestamp
                    ).toFormat("MMM d, hh:mm a")}
                  </Text>
                ) : (
                  <Text
                    testID="test-measure-timestamp"
                    capsSmall
                    style={textStyles.colorDarkBlue}
                  >
                    {DateTime.fromSeconds(
                      data.lastReading.measure_timestamp
                    ).toFormat("MMMM d, hh:mm a")}
                  </Text>
                )}

                <View>
                  {trendPercent !== undefined && (
                    <Chip
                      title={
                        trendPercent +
                        " " +
                        LocalizedStrings.screens.readingDetails.days.replace(
                          "{{DAYS}}",
                          "30"
                        )
                      }
                      color={chipColor}
                      iconName={chipIcon}
                    />
                  )}
                  {readingQualifierString !== undefined && (
                    <Spacing vertical={2} />
                  )}
                  {readingQualifierString !== undefined && (
                    <Chip
                      testID="test-qualier"
                      title={readingQualifierString}
                      color={theme.colors.midBlue2}
                    />
                  )}
                </View>
              </View>

              {type !== ScreenTypeEnum.PHONE && (
                <View style={styles.gap}>
                  <Button
                    type="outline"
                    title={LocalizedStrings.screens.readingDetails.fullScreen}
                    accessibilityLabel={"Full Screen"}
                    icon={{
                      name: "maximize",
                      type: "feather",
                      size: 24,
                      color: theme.colors.darkGreyBlue
                    }}
                    onPress={() =>
                      navigation.navigate("ChartFullModal", {
                        readingType: DeviceType_url_encode(readingType),
                        days: CHART_OPTIONS_MINUS[chartIndex]
                      })
                    }
                  />

                  <Button
                    title={
                      LocalizedStrings.screens.readingDetails.seeAllReadings
                    }
                    type="outline"
                    onPress={() =>
                      navigation.navigate("ReadingsHistory", {
                        readingType: DeviceType_url_encode(readingType),
                        days: CHART_OPTIONS_MINUS[chartIndex]
                      })
                    }
                  />
                </View>
              )}

              {type === ScreenTypeEnum.PHONE && (
                <Feather
                  style={styles.latestReadingDroplet}
                  name={
                    readingType === DeviceTypeEnum.BLOOD_PRESSURE
                      ? "activity"
                      : "droplet"
                  }
                  size={24}
                  color={theme.colors.tealBlue}
                />
              )}
            </View>
          )}

          <View
            style={[viewStyles.cardContainer, styles.gap]}
            onLayout={onLayout}
          >
            <SegmentedControl
              style={type !== ScreenTypeEnum.PHONE && styles.tabletWidthLimit}
              values={CHART_OPTIONS}
              selectedIndex={chartIndex}
              onChange={(event) => {
                setChartIndex(event.nativeEvent.selectedSegmentIndex);
              }}
            />
            {isFetching === false ? (
              <>
                {data.min !== undefined && data.max !== undefined ? (
                  <>
                    <View
                      style={[
                        styles.row,
                        type !== ScreenTypeEnum.PHONE && styles.tabletWidthLimit
                      ]}
                    >
                      <RenderMeasurement
                        title={LocalizedStrings.screens.readingDetails.min}
                        value={
                          Math.round(data.min[accessor[0]]) +
                          (data.min[accessor[1]] !== undefined
                            ? "/" + Math.round(data.min[accessor[1]])
                            : "")
                        }
                      />
                      <RenderMeasurement
                        title={LocalizedStrings.screens.readingDetails.avg}
                        value={data.average}
                      />
                      <RenderMeasurement
                        title={LocalizedStrings.screens.readingDetails.max}
                        value={
                          Math.round(data.max[accessor[0]]) +
                          (data.max[accessor[1]] !== undefined
                            ? "/" + Math.round(data.max[accessor[1]])
                            : "")
                        }
                      />
                    </View>
                    <Chart
                      // data={data?.data?.slice(1)}
                      data={data?.data}
                      hasAxis={true}
                      width={viewWidth}
                      height={type === ScreenTypeEnum.PHONE ? 250 : 400}
                      accessors={accessor as ReadingAccessorType[]}
                      accessorsMargin={{ x: 0, y: 30 }}
                      unit={unit}
                    />
                  </>
                ) : (
                  <>
                    <Spacing vertical={8} />
                    <Text
                      body
                      accessibilityLabel={"No readings taken"}
                      style={[
                        textStyles.colorGrey0,
                        textStyles.textAlignCenter
                      ]}
                    >
                      {LocalizedStrings.screens.readingDetails.noReadingsTaken}
                    </Text>
                    <Spacing vertical={8} />
                  </>
                )}
              </>
            ) : (
              <>
                <Spacing vertical={8} />
                <ActivityIndicator />
                <Spacing vertical={8} />
              </>
            )}

            {type === ScreenTypeEnum.PHONE && (
              <Button
                type="outline"
                accessibilityLabel={"Full Screen"}
                title={LocalizedStrings.screens.readingDetails.fullScreen}
                icon={{
                  name: "maximize",
                  type: "feather",
                  size: 24,
                  color: theme.colors.darkGreyBlue
                }}
                onPress={() =>
                  navigation.navigate("ChartFullModal", {
                    readingType: DeviceType_url_encode(readingType),
                    days: CHART_OPTIONS_MINUS[chartIndex]
                  })
                }
              />
            )}
          </View>

          {/* <LandingBanner
            variant="secondary"
            title="RECOMMENDATION"
            description="Your Blood Sugar is below average today, try..."
            buttonTitle="Learn More"
            icon="alert-triangle"
            onButtonPress={() => {}}
          /> */}

          {type === ScreenTypeEnum.PHONE && (
            <Button
              title={LocalizedStrings.screens.readingDetails.history}
              accessibilityLabel={"History"}
              onPress={() =>
                navigation.navigate("ReadingsHistory", {
                  readingType: DeviceType_url_encode(readingType),
                  days: CHART_OPTIONS_MINUS[chartIndex]
                })
              }
            />
          )}
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      gap: 20,
      margin: 20
    },
    flex1: {
      flex: 1
    },
    row: {
      flexDirection: "row",
      justifyContent: "space-evenly"
    },
    stretch: {
      alignSelf: "stretch"
    },
    alignItemsCenter: {
      alignItems: "center"
    },
    alignItemsStart: {
      alignItems: "flex-start"
    },
    gap: {
      gap: 10
    },
    latestReadingDroplet: {
      position: "absolute",
      left: 15,
      top: 15
    },
    tabletWidthLimit: {
      maxWidth: 300
    }
  };
});

export default ReadingsDetailsScreen;
