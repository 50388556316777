import { NavigationProp, useNavigation } from "@react-navigation/native";
import { makeStyles, useTheme } from "@rneui/themed";
import React from "react";
import { View, FlatList } from "react-native";
import Button from "../../components/ui/Button";
import IconButton from "../../components/ui/IconButton";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Spacing from "../../components/ui/Spacing";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import useViewStyles from "../../components/ui/styles/useViewStyles";
import Text from "../../components/ui/Text";
import { DashboardStackParamList } from "../../navigation/DashboardStackNavigator";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const DATA = [1, 2, 3, 4, 5];

type ScreenProp = NavigationProp<DashboardStackParamList, "MyGoals">;

const ListItem = () => {
  const { theme } = useTheme();
  const styles = useStyles();
  const viewStyles = useViewStyles();
  const textStyles = useTextStyles();

  const navigation = useNavigation<ScreenProp>();

  return (
    <View style={[viewStyles.cardContainer, styles.listContainer]}>
      <View style={styles.listHeader}>
        <Text h3 style={textStyles.colorDarkGreyBlue}>
          Goal title
        </Text>

        <IconButton
          color={theme.colors.tealBlue}
          iconColor={theme.colors.white}
          icon="arrow-right"
          onPress={() => navigation.navigate("GoalDetails")}
        />
      </View>
      <Text body style={textStyles.colorDarkGreyBlue}>
        Category name
      </Text>
      <Text capsSmall style={textStyles.colorDarkGreyBlue}>
        {LocalizedStrings.screens.goals.targetDate}:{" "}
        <Text bodySmall style={textStyles.colorGreyBlue}>
          Apr 17, 2024
        </Text>
      </Text>
    </View>
  );
};

const ListSeparatorAndHeader = () => <Spacing vertical={4} />;

const ListFooter = () => {
  const navigation = useNavigation<ScreenProp>();

  return (
    <View>
      <Spacing vertical={4} />

      <Button
        title={LocalizedStrings.screens.goals.addNewGoal}
        onPress={() => navigation.navigate("AddGoalCategory")}
      />
      <Spacing vertical={4} />
    </View>
  );
};

const MyGoalsScreen = () => {
  const styles = useStyles();
  return (
    <ScreenContainer>
      <FlatList
        data={DATA}
        keyExtractor={(item, index) => index.toString()}
        renderItem={() => <ListItem />}
        contentContainerStyle={styles.container}
        ListHeaderComponent={ListSeparatorAndHeader}
        ListFooterComponent={ListFooter}
        ItemSeparatorComponent={ListSeparatorAndHeader}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginHorizontal: 20
    },
    listContainer: {
      gap: 10
    },
    listHeader: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }
  };
});

export default MyGoalsScreen;
