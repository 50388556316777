import { ScrollView, View } from "react-native";
import { makeStyles, useTheme, Divider } from "@rneui/themed";

import Feather from "react-native-vector-icons/Feather";

import { logOut } from "common/redux/AuthSlice";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import PatientHeader from "../../components/myaccount/PatientHeader";
import { useAppDispatch } from "common/redux";
import { MyAccountStackParamList } from "../../navigation/MyAccountStackNavigator";
import { NavigationProp, useNavigation } from "@react-navigation/native";
import AppVersion from "../../components/AppVersion";
import { Alert_close, Alert_show } from "common/helpers/AlertHelper";
import useScreenType, { ScreenTypeEnum } from "../../hooks/useScreenType";
import TouchableHighlight from "../../components/ui/TouchableHighlight";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const ListItem = ({ title, onPress = undefined }) => {
  const { theme } = useTheme();
  const styles = useStyles();

  return (
    <TouchableHighlight
      accessibilityLabel={title}
      onPress={onPress}
      underlayColor={theme.colors.white}
    >
      <View style={styles.listRow}>
        <Text body style={styles.listTextColor}>
          {title}
        </Text>
        {onPress !== undefined && (
          <Feather
            size={32}
            name="chevron-right"
            color={theme.colors.tealBlue}
          />
        )}
      </View>
    </TouchableHighlight>
  );
};

const MyAccountScreen = () => {
  const navigation = useNavigation<NavigationProp<MyAccountStackParamList>>();

  const dispatch = useAppDispatch();
  const styles = useStyles();
  const { type } = useScreenType();

  return (
    <ScreenContainer>
      <ScrollView>
        <PatientHeader />

        {/* remove spacing since we are removing the avatar placeholder */}
        {/* <Spacing vertical={5} /> */}

        <View
          style={type !== ScreenTypeEnum.PHONE && styles.tabletContainer}
          testID={"Personal Information"}
          accessibilityLabel={"Personal Information"}
        >
          <ListItem
            title={LocalizedStrings.screens.personalInformation.title}
            onPress={() => navigation.navigate("PersonalInfo")}
          />
          <Divider />
          {/*<ListItem
          title={'Doctor Sharing'}
          onPress={() => navigation.navigate('DoctorSharing')}
        />
        <Divider />
        <ListItem
          title={'Family Sharing'}
          onPress={() => navigation.navigate('FamilySharing')}
        />
        <Divider />
        <ListItem title={'Help'} onPress={() => navigation.navigate('Help')} />
        <Divider />
  */}
          <ListItem
            title={LocalizedStrings.screens.myAccount.settings}
            onPress={() => navigation.navigate("Settings")}
          />

          <Divider />
          <ListItem
            title={LocalizedStrings.screens.myAccount.logOut}
            onPress={() => {
              Alert_show({
                dispatch,
                id: "logOut",
                title: LocalizedStrings.screens.myAccount.logOutModalTitle,
                content: LocalizedStrings.screens.myAccount.logOutModalContent,
                buttons: [
                  {
                    text: LocalizedStrings.common.yes,
                    onPress: () => {
                      dispatch(logOut(true));
                      Alert_close({ dispatch, id: "logOut" });
                    }
                  },
                  { text: LocalizedStrings.common.no, style: "cancel" }
                ]
              });
            }}
          />
          {type === ScreenTypeEnum.PHONE && <Divider />}
        </View>

        <AppVersion />
        <Spacing vertical={5} />
      </ScrollView>
    </ScreenContainer>
  );
};

const MARGIN_SIDES = 20;

const useStyles = makeStyles((theme) => {
  return {
    listRow: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginVertical: 10,
      paddingHorizontal: MARGIN_SIDES
    },
    listTextColor: {
      color: theme.colors.greyBlue
    },
    tabletContainer: {
      margin: 20,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: theme.colors.grey4,
      overflow: "hidden"
    }
  };
});

export default MyAccountScreen;
