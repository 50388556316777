import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Image, makeStyles } from "@rneui/themed";

import useAppState from "react-native-appstate-hook";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import {
  Biometrics_sensorName,
  Biometrics_verify
} from "../../helpers/BiometricsHelpers";
import { biometricsImage } from "../../assets/auth/onboarding";
import TouchableOpacity from "../../components/ui/TouchableOpacity";

interface IProps {
  onSuccess: () => void;
  onFailure: () => void;
}

const BiometricsValidationScreen = ({ onSuccess, onFailure }: IProps) => {
  const { appState } = useAppState();
  const styles = useStyles();

  const [sensorName, setSensorName] = useState<string>();
  const [cancelled, setCancelled] = useState<boolean>();

  const biometricPrompt = () => {
    Biometrics_verify("Verify your identity")
      .then((result) => {
        if (result) onSuccess();
        else {
          onFailure();
          setCancelled(true);
        }
      })
      .catch((error) => {
        onFailure();
        setCancelled(true);
      });
  };

  useEffect(() => {
    if (cancelled || appState !== "active") return;
    biometricPrompt();
  }, [appState]);

  useEffect(() => {
    Biometrics_sensorName()
      .then((name) => setSensorName(name))
      .catch((error) => console.log(error));
  }, []);

  return (
    <ScreenContainer
      innerStyle={styles.container}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <TouchableOpacity onPress={biometricPrompt}>
        <View style={styles.biometricsButton}>
          <Image
            style={styles.image}
            source={biometricsImage}
            resizeMode="contain"
          />
          <Text caps style={styles.textColor}>
            {sensorName}
          </Text>
        </View>
      </TouchableOpacity>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      alignItems: "center",
      justifyContent: "center"
    },
    biometricsButton: {
      backgroundColor: "#F2F2F2CC",
      borderRadius: 10,
      padding: 20,
      alignItems: "center",
      gap: 20
    },
    image: {
      width: 100,
      height: 100
    },
    textColor: {
      color: "#7D7D7D"
    }
  };
});

export default BiometricsValidationScreen;
