import React from "react";
import { View, ScrollView } from "react-native";
import { makeStyles } from "@rneui/themed";

import { Alert_show } from "common/helpers/AlertHelper";
import { useAppDispatch } from "common/redux";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import ProgressBar from "../../components/ui/ProgressBar";
import Button from "../../components/ui/Button";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const GoalDetailsScreen = ({ navigation }) => {
  const dispatch = useAppDispatch();

  const styles = useStyles();
  const textStyles = useTextStyles();
  return (
    <ScreenContainer>
      <ScrollView>
        <View style={styles.container}>
          <Text h1 style={textStyles.colorDarkGreyBlue}>
            Goal title
          </Text>
          <Text body style={textStyles.colorDarkGreyBlue}>
            Category name
          </Text>

          <Spacing vertical={2} />

          <Text capsSmall style={textStyles.colorDarkGreyBlue}>
            {LocalizedStrings.screens.goals.creationDate}:{" "}
            <Text bodySmall style={textStyles.colorGreyBlue}>
              Jan 17, 2023
            </Text>
          </Text>
          <Text capsSmall style={textStyles.colorDarkGreyBlue}>
            {LocalizedStrings.screens.goals.targetDate}:{" "}
            <Text bodySmall style={textStyles.colorGreyBlue}>
              Apr 17, 2024
            </Text>
          </Text>

          <Spacing vertical={2} />

          <Text h4 style={textStyles.colorDarkGreyBlue}>
            {LocalizedStrings.screens.goals.createdBy}
          </Text>
          <Text body style={textStyles.colorGreyBlue}>
            Nurse Mindy Smith
          </Text>
          <Spacing vertical={2} />
          <Text h4 style={textStyles.colorDarkGreyBlue}>
            {LocalizedStrings.screens.goals.detail}
          </Text>
          <Text body style={textStyles.colorGreyBlue}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </Text>

          <Spacing vertical={2} />

          <Text h4 style={textStyles.colorDarkGreyBlue}>
            {LocalizedStrings.screens.goals.progress}
          </Text>
          <ProgressBar progress={0.25} />

          <Text body style={textStyles.colorGreyBlue}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt.
          </Text>

          <Spacing vertical={2} />

          <View style={styles.buttonRow}>
            <Button
              containerStyle={styles.flex1}
              type="outline"
              title={LocalizedStrings.common.modify}
              onPress={() => navigation.navigate("EditGoal")}
            />
            <Button
              containerStyle={styles.flex1}
              type="outline"
              title={LocalizedStrings.common.delete}
              onPress={() =>
                Alert_show({
                  dispatch,
                  title:
                    LocalizedStrings.screens.goals.deleteModalTitle.replace(
                      "_TITLE_",
                      "test"
                    ),
                  content: LocalizedStrings.screens.goals.deleteModalContent,
                  buttons: [
                    {
                      text: LocalizedStrings.common.yes
                    },
                    {
                      text: LocalizedStrings.common.cancel,
                      style: "cancel"
                    }
                  ]
                })
              }
            />
          </View>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    container: {
      gap: 10,
      margin: 20
    },
    buttonRow: {
      flexDirection: "row",
      gap: 20
    },
    flex1: {
      flex: 1
    }
  };
});

export default GoalDetailsScreen;
