import { Divider, makeStyles, useTheme } from "@rneui/themed";
import { useEffect, useRef, useState } from "react";
import {
  ScrollView,
  TouchableOpacity,
  View,
  Animated,
  Easing,
  Platform
} from "react-native";
import Accordion from "react-native-collapsible/Accordion";

import Feather from "react-native-vector-icons/Feather";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Spacing from "../../components/ui/Spacing";
import Text from "../../components/ui/Text";

import DATA from "./FAQ.json";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const ListItemHeader = ({ section, isActive }) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const textStyles = useTextStyles();

  const rotation = useRef(new Animated.Value(isActive ? -180 : 0)).current;

  useEffect(() => {
    Animated.timing(rotation, {
      toValue: isActive ? -180 : 0,
      duration: 250,
      easing: Easing.linear,
      useNativeDriver: Platform.OS !== "web"
    }).start();
  }, [isActive]);

  const spin = rotation.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "1deg"]
  });

  return (
    <View style={styles.listHeader}>
      <Text
        h4
        style={[
          textStyles.colorDarkGreyBlue,
          styles.listTextSpacing,
          styles.flex1
        ]}
      >
        {section.title}
      </Text>
      <Animated.View style={{ transform: [{ rotate: spin }] }}>
        <Feather name="chevron-down" size={32} color={theme.colors.tealBlue} />
      </Animated.View>
    </View>
  );
};

const ListItemContent = ({ section }) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  return (
    <View style={styles.listItemContent}>
      {section.content.map((text) => (
        <Text
          body
          style={[textStyles.colorDarkGreyBlue, styles.listTextSpacing]}
          key={text}
        >
          {text}
        </Text>
      ))}
    </View>
  );
};

const HelpScreen = () => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  const [activeSections, setActiveSections] = useState<string[]>([]);

  const updateSections = (activeSections) => {
    setActiveSections(activeSections);
  };

  return (
    <ScreenContainer>
      <ScrollView>
        <View style={styles.contentContainer}>
          <Text h2 style={textStyles.colorDarkGreyBlue}>
            {LocalizedStrings.screens.help.faqs}
          </Text>

          <Spacing vertical={6} />
          <Accordion
            touchableComponent={TouchableOpacity}
            expandMultiple
            activeSections={activeSections}
            sections={DATA}
            renderHeader={(section, index, isActive) => (
              <ListItemHeader section={section} isActive={isActive} />
            )}
            renderFooter={() => <Divider />}
            renderContent={(section) => <ListItemContent section={section} />}
            onChange={updateSections}
          />
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    contentContainer: {
      margin: 20
    },
    flex1: {
      flex: 1
    },
    listHeader: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      marginVertical: 5
    },
    listItemContent: {
      marginBottom: 10
    },
    listTextSpacing: {
      marginVertical: 5
    }
  };
});

export default HelpScreen;
