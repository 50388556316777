import { useEffect, useState } from "react";
import { Platform, View } from "react-native";
import { makeStyles, Switch } from "@rneui/themed";
import { EdgeInsets, useSafeAreaInsets } from "react-native-safe-area-context";
import { useSelector } from "react-redux";

import Button from "../../components/ui/Button";
import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import StepIndicator from "../../components/ui/StepIndicator";

import {
  Biometrics_sensorName,
  Biometrics_verify
} from "../../helpers/BiometricsHelpers";
import { PushNotificationsHelper_isSupported } from "common/helpers/firebase/PushNotificationsHelper";
import { RootState, useAppDispatch } from "common/redux";
import { setAuthFlowFinished } from "common/redux/AuthSlice";
import { setBiometricsEnabled } from "common/redux/SettingsSlice";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const BiometricsScreen = ({ navigation }) => {
  const insets = useSafeAreaInsets();
  const styles = useStyles(insets);

  const dispatch = useAppDispatch();
  const { biometricsEnabled, notificationsEnabled } = useSelector(
    (state: RootState) => state.settings
  );

  const [biometricsSensorName, setBiometricsSensorName] = useState<string>();

  useEffect(() => {
    Biometrics_sensorName()
      .then((sensorName) => setBiometricsSensorName(sensorName))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (biometricsEnabled) {
      Biometrics_verify(LocalizedStrings.screens.biometrics.verifyYourIdentity)
        .then((result) => {
          dispatch(setBiometricsEnabled(result));
        })
        .catch((error) => {
          dispatch(setBiometricsEnabled(false));
        });
    } else {
      dispatch(setBiometricsEnabled(false));
    }
  }, [biometricsEnabled]);

  return (
    <ScreenContainer
      innerStyle={styles.container}
      limitWidthTabletScreen
      disableBottomInsets
    >
      <StepIndicator size={5} index={3} />
      <View style={styles.formContainer}>
        <Text h1 style={styles.headerText}>
          {LocalizedStrings.screens.biometrics.identityVerified}
        </Text>

        <Text bodyLarge style={styles.contentText}>
          {LocalizedStrings.screens.biometrics.useSensorToLogIn.replace(
            "{{BIOMETRICS_SENSOR_NAME}}",
            biometricsSensorName
          )}
        </Text>

        <View style={styles.row}>
          <Text bodySmall style={styles.contentText}>
            {LocalizedStrings.common.enable} {biometricsSensorName}
          </Text>
          <Switch
            disabled={biometricsSensorName === undefined}
            value={biometricsEnabled}
            onChange={() => {
              dispatch(setBiometricsEnabled(!biometricsEnabled));
            }}
          />
        </View>
      </View>

      <Button
        title={LocalizedStrings.common.continue}
        containerStyle={styles.buttonContainer}
        onPress={() => {
          PushNotificationsHelper_isSupported().then((isSupported) => {
            if (
              isSupported === false ||
              (isSupported && notificationsEnabled)
            ) {
              dispatch(setAuthFlowFinished());
            } else {
              navigation.reset({
                index: 0,
                routes: [{ name: "TurnOnNotifications" }]
              });
            }
          });
        }}
      />
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme, insets: EdgeInsets) => {
  const bottomInsets = insets.bottom + (Platform.OS === "android" ? 10 : 0);
  const marginBottom = Math.max(bottomInsets, 10);

  return {
    container: {
      flex: 1,
      marginHorizontal: 10
    },
    formContainer: {
      flex: 1,
      gap: 20,
      justifyContent: "center",
      marginBottom: 120
    },
    contentText: {
      color: theme.colors.greyBlue
    },
    buttonContainer: {
      marginBottom
    },
    headerText: {
      color: theme.colors.darkGreyBlue
    },
    row: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    }
  };
});

export default BiometricsScreen;
