import { useMemo } from "react";
import { SectionList, View, Image } from "react-native";
import { makeStyles, useTheme } from "@rneui/themed";

import { DateTime } from "luxon";
import Feather from "react-native-vector-icons/Feather";

import { NavigationProp, useNavigation } from "@react-navigation/native";
import useTextStyles from "../../components/ui/styles/useTextStyles";
import MemberLinkedEntitiesEnum from "common/enums/MemberLinkedEntitiesEnum";
import useGetAuthenticatedMember from "common/hooks/useGetAuthenticatedMember";
import { getNameOrUsername, maskPhoneNumber } from "common/helpers/helpers";

import ScreenContainer from "../../components/ui/ScreenContainer";
import Text from "../../components/ui/Text";
import Spacing from "../../components/ui/Spacing";
import Button from "../../components/ui/Button";
import { Alert_show } from "common/helpers/AlertHelper";
import { CalendarHelper_addEvent } from "../../helpers/CalendarHelper";
import { DashboardStackParamList } from "../../navigation/DashboardStackNavigator";
import { useAppDispatch } from "common/redux";
import { staffHealthWorker } from "../../assets/common";
import ActivityIndicator from "../../components/ui/ActivityIndicator";
import TouchableOpacity from "../../components/ui/TouchableOpacity";
import UserTypeInner from "common/types/UserTypeInner";
import LocalizedStrings from "../../helpers/LocalizedStrings";

const DATA = [
  {
    title: "Tuesday, January 17, 2023",
    data: ["09:00 - 10:00 AM", "10:00 - 11:00 AM", "2:00 - 2:30 PM"]
  },
  {
    title: "Friday, January 18, 2023",
    data: [
      "09:00 - 10:00 AM",
      "1:00 - 2:00 PM",
      "3:00 - 4:00 PM",
      "4:30 - 5:00 PM"
    ]
  }
];

type ScreenProp = NavigationProp<DashboardStackParamList, "BookAppointment">;

const ListHeader = ({ isReschedule, carer, role }) => {
  const { theme } = useTheme();
  const styles = useStyles();
  const textStyles = useTextStyles();

  return (
    <View>
      <Spacing vertical={4} />
      {isReschedule === false && (
        <View style={styles.row}>
          <Image style={styles.avatar} source={staffHealthWorker} />
          <Spacing horizontal={4} />
          <View>
            <Text h4 style={textStyles.colorDarkGreyBlue}>
              {getNameOrUsername(carer)}
            </Text>
            <Spacing vertical={1} />
            <Text capsSmall style={textStyles.colorDarkGreyBlue}>
              {role}
            </Text>
            <Spacing vertical={2} />
            <Text body style={textStyles.colorDarkGreyBlue}>
              <Feather
                name="phone"
                color={theme.colors.darkGreyBlue}
                size={16}
              />{" "}
              {maskPhoneNumber(carer?.phone)}
            </Text>
          </View>
        </View>
      )}
      {isReschedule && (
        <Text h3 style={textStyles.colorDarkGreyBlue}>
          {LocalizedStrings.screens.bookAppointment.selectDate}
        </Text>
      )}

      {isReschedule === false && (
        <>
          <Spacing vertical={4} />
          <Text body style={textStyles.colorGrey0}>
            {LocalizedStrings.screens.bookAppointment.listHeader}
          </Text>
        </>
      )}

      <Spacing vertical={2} />
    </View>
  );
};

const ListFooter = ({ carer }) => {
  const styles = useStyles();
  const textStyles = useTextStyles();
  const navigation = useNavigation<ScreenProp>();

  return (
    <View>
      <Spacing vertical={2} />
      <Text bodySmall style={[textStyles.colorGreyBlue, styles.alignCenter]}>
        {LocalizedStrings.screens.bookAppointment.needFasterResponse}
      </Text>
      <TouchableOpacity onPress={() => navigation.navigate("Chat")}>
        <Text link style={[textStyles.colorTealBlue, styles.alignCenter]}>
          {LocalizedStrings.screens.bookAppointment.sendAMessage.replace(
            "{{NAME}}",
            carer.first
          )}
        </Text>
      </TouchableOpacity>
      <Spacing vertical={4} />
    </View>
  );
};

const ListSectionSeparator = () => <Spacing vertical={4} />;
const ListItemSeparator = () => <Spacing vertical={2} />;
const RenderItem = ({ item, section }) => {
  const dispatch = useAppDispatch();

  const showConfirmationModal = () => {
    Alert_show({
      dispatch,
      title: LocalizedStrings.screens.bookAppointment.appointmentConfirmed,
      content: `${section.title}${"\n"}${item}`,
      buttons: [
        {
          text: LocalizedStrings.screens.bookAppointment.addToCalendar,
          onPress: () =>
            setTimeout(
              () =>
                CalendarHelper_addEvent({
                  title: "Test Event",
                  description: "Test description",
                  location: "New York",
                  startDate: DateTime.now().plus({ hours: 3 }),
                  dispatch
                }),
              1000
            )
        },
        { text: LocalizedStrings.common.finish, style: "cancel" }
      ]
    });
  };

  return (
    <Button
      type="outline"
      title={item}
      onPress={() =>
        Alert_show({
          dispatch,
          title: LocalizedStrings.screens.bookAppointment.youHaveSelected,
          content: `${section.title}${"\n"}${item}`,
          buttons: [
            {
              text: LocalizedStrings.common.confirm,
              onPress: showConfirmationModal
            },
            { text: LocalizedStrings.common.cancel, style: "cancel" }
          ]
        })
      }
    />
  );
};

const ListHeaderComponent = (
  isReschedule: boolean,
  carer: UserTypeInner,
  role: string
) => <ListHeader isReschedule={isReschedule} carer={carer} role={role} />;

const ListFooterComponent = (carer: UserTypeInner) => (
  <ListFooter carer={carer} />
);

const BookAppointmentScreen = ({ route }) => {
  const styles = useStyles();
  const textStyles = useTextStyles();

  const { isReschedule, user_id } = route.params;

  const { data: patient, isLoading } = useGetAuthenticatedMember(false, [
    MemberLinkedEntitiesEnum.NURSE,
    MemberLinkedEntitiesEnum.PROVIDER
  ]);

  const { carer, role } = useMemo(() => {
    if (patient?.assigned_nurse?.user_id === user_id)
      return { carer: patient?.assigned_nurse, role: "NURSE" };
    else if (patient?.patient_provider?.user_id === user_id)
      return { carer: patient?.patient_provider, role: "PROVIDER" };
    else return {};
  }, [patient, user_id]);

  return (
    <ScreenContainer>
      {carer === undefined && isLoading === false && (
        <View style={[styles.flex1, styles.center]}>
          <Text h4 style={textStyles.colorDarkGreyBlue}>
            {LocalizedStrings.screens.bookAppointment.noCarersFound}
          </Text>
        </View>
      )}
      {carer !== undefined && isLoading && (
        <ActivityIndicator style={styles.flex1} />
      )}
      {carer !== undefined && isLoading === false && (
        <SectionList
          contentContainerStyle={styles.container}
          sections={DATA}
          keyExtractor={(item, index) => item + index}
          renderItem={RenderItem}
          ListHeaderComponent={ListHeaderComponent(isReschedule, carer, role)}
          ListFooterComponent={ListFooterComponent(carer)}
          SectionSeparatorComponent={ListSectionSeparator}
          ItemSeparatorComponent={ListItemSeparator}
          renderSectionHeader={({ section: { title } }) => (
            <Text bodySmall style={textStyles.colorGreyBlue}>
              {title}
            </Text>
          )}
        />
      )}
    </ScreenContainer>
  );
};

const AVATAR_SIZE = 128;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      marginHorizontal: 20
    },
    avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      borderRadius: AVATAR_SIZE / 8
    },
    row: {
      flexDirection: "row",
      alignItems: "center"
    },
    alignCenter: {
      alignSelf: "center"
    },
    center: {
      justifyContent: "center",
      alignItems: "center"
    },
    flex1: {
      flex: 1
    }
  };
});

export default BookAppointmentScreen;
